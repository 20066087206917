import { Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';

import { AboutService } from 'src/app/services/about.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';


@Component({
  selector: 'calsoft-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  aboutData: any;
  @Input() public pageTitle: any
  @Input() public homePageBuilder: any;
  loaded:any=false;
  layoutype: any=0;
  homePageLayoutLoadedMob: any;
  homePageLayoutLoadedWeb: any;
  page: number;
  mobileLayout: boolean;
  size: any;
  constructor(
    private aboutService: AboutService,
    public meta: Meta, public title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private configService:ConfigServiceService
  ) {


  }

  ngOnInit(): void {

    console.log("as");
    

    if(this.route.snapshot.params['pageId']!=undefined){

      this.pageTitle=this.route.snapshot.params['pageId'];
    }

    if(this.router.url.includes('/pages/')){
      this.pageTitle = this.route.snapshot.params['pageId'];
    }

    this.title.setTitle(this.router.url.replace('/', '').toUpperCase());
    this.getConfigData();
   
  }

  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          
   
          this.homePageLayoutLoadedMob=data['homePageLayoutLoadedMob'];
          this.homePageLayoutLoadedWeb=data['homePageLayoutLoadedWeb'];

          this.getAboutData();
    
          }, error => {

          }
          );
}


  getAboutData() {

    this.page = 0;
    if (window.innerWidth > 768) {

      this.mobileLayout=false;
      this.size = this.homePageLayoutLoadedWeb==undefined?3: this.homePageLayoutLoadedWeb;
    } else {
      this.mobileLayout=true;
      this.size = this.homePageLayoutLoadedMob==undefined?5:this.homePageLayoutLoadedMob;
    }


    this.aboutService.getPageByIdNew(this.pageTitle,this.page,this.size).subscribe(
      (res) => {
        this.aboutData = res['content'];

        this.layoutype=res['type'];
        this.homePageBuilder=res['homePageBuilder'];
        let currentURL = window.location.href;


        if(!this.pageTitle.includes("footer")){
          let type='article';
          this.meta.addTags([
            { name: 'keywords', content:  res['metaKeywords'] },
            { name: 'description', content: res['metaDescription'] },
            { name: 'og:url', currentURL },
            { name: 'og:description', content: res['metaDescription'] },
            { name: 'twitter:description', content: res['metaDescription'] },
            { name: 'og:type', type },
         ]);
  
         if(res['rootTemplate']!=undefined){
          this.title.setTitle(res['rootTemplate']);
          if(res['rootTemplate']!=null){
            this.title.setTitle(res['rootTemplate']);
            this.meta.addTags([
              { name: 'og:title', content: res['rootTemplate'] },
              { name: 'twitter:title', content: res['rootTemplate'] },
            ]);
          }
        }
        }

       
        this.loaded=true;
      }
    )
  }



}
