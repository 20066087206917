
<div *ngIf="popupType == 2" class="new-Type" style="padding-left: 1.5rem;
      padding-right: 1.5rem;background-color: #000;">
  <div class="popup-header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="popup-title"></span>
    <button (click)="close()" mat-icon-button class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div style="background-color: #000;color:#fff;">
    <div>
      <h2 class="heading-popup">
        {{formTitle || 'Form'}}
      </h2>
    </div>
    <p class="heading-popup-description">
      Got a question or an idea? Feel free to reach out to us anytime, and we'll get back
      to you as quickly as possible with the support you need.
    </p>
  </div>

  <mat-dialog-content class="popup-content"
    style="margin: 0px;margin-bottom: 2rem;max-height: 100vh;z-index: 10000;overflow: hidden;"
    ngStyle.lt-md="max-height: 100%;">
    <div fxLayout="column" class="popup-body">
      <div class="form-container" fxLayout="column" fxLayoutGap="10px">
        <div *ngIf="isLoading" class="loading-container">
          <mat-spinner diameter="50"></mat-spinner>
          <div class="loader"></div>
        </div>

        <dynamic-form *ngIf="!isLoading && regConfig?.length" [newPopup]="2" [layout]="1" fxFlex="100"
          [fields]="regConfig" (formSubmit)="submit($event)">
        </dynamic-form>
      </div>

      <p class="privacy-text">We’ll keep your information in our CRM to respond to your request. For more details,
        consult our <a class="privacy-link" href="https://www.calsoftgroup.com/privacy" target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </a>.
      </p>
    </div>
  </mat-dialog-content>
</div>

<div *ngIf="popupType != 2 && popupType !=3">
  <div mat-dialog-title class="title-head" class="primary" fxLayout="row" fxLayoutAlign="space-between center"
    color="primary">
    <span style="color: white; padding: 10px;">
      {{formTitle || 'Form'}}
    </span>
    <button (click)="close()" mat-icon-button>
      <mat-icon style="color: white;">
        close
      </mat-icon>
    </button>
  </div>
  <mat-dialog-content style="MARGIN: 0PX; 
      padding: 0px;" ngStyle.lt-md="max-height: 100%;">

    <div fxLayout="column" class="padding-10">

      <div fxFlex="100" class="form">
        <div *ngIf="init" fxFlex="100">
          <dynamic-form [layout]="1" fxFlex="100" [fields]="regConfig" (formSubmit)="submit($event)">
          </dynamic-form>
        </div>
      </div>

    </div>

  </mat-dialog-content>
</div>

<div *ngIf="popupType == 3" class="new-Type" style="padding-left: 1.5rem;
      padding-right: 1.5rem;">
  <div class="popup-header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="popup-title"></span>
    <button (click)="close()" mat-icon-button class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>
    <div>
      <h2 class="heading-popup">
        {{formTitle || 'Form'}}
      </h2>
    </div>
    <p class="heading-popup-description">
      Got a question or an idea? Feel free to reach out to us anytime, and we'll get back
      to you as quickly as possible with the support you need.
    </p>
  </div>

  <mat-dialog-content class="popup-content"
    style="margin-bottom: 2rem;max-height: 100vh;z-index: 10000;overflow: hidden;"
    ngStyle.lt-md="max-height: 100%;">
    <div fxLayout="column" class="popup-body">
      <div class="form-container" fxLayout="column" fxLayoutGap="10px">
        <div *ngIf="isLoading" class="loading-container">
          <mat-spinner diameter="50"></mat-spinner>
          <div class="loader"></div>
        </div>

        <dynamic-form *ngIf="!isLoading && regConfig?.length" [newPopup]="2" [layout]="1" fxFlex="100"
          [fields]="regConfig" (formSubmit)="submit($event)">
        </dynamic-form>
      </div>

      <p class="privacy-text">We’ll keep your information in our CRM to respond to your request. For more details,
        consult our <a class="privacy-link" href="https://www.calsoftgroup.com/privacy" target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </a>.
      </p>
    </div>
  </mat-dialog-content>
</div>

