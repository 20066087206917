<div *ngIf="item.customField13==0">
  <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChange($event,i)">
    <mat-tab *ngFor="let images of item.images let i=index" [label]="images.customField15">
      <div *ngIf="images.customField3=='Category' || images.customField3=='ProductScope' ">
        <div *ngIf="i==index">

          <div *ngIf="item.layout=='type-2';else otherCondition">


            <calsoft-product-carousel [heightMobile]="productImageHeightMob" [item]="item"
              [widthMobile]="productImageWidthMob" [data]="data" [height]="productImageHeight"
              [width]="productImageWidth" [imageType]="productImageType"
              [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon" [slidesPerView]="item.customField29"
              [showCartIncremented]="showCartIncremented"
              [other]="images.customField3=='Category'?'category' :'productScope'"
              [id]="images.customField3=='Category'?images.customField4:images.customField20"
              [sortOrder]="images.customField18" [header]="images.customField15" [item]="item">
            </calsoft-product-carousel>

          </div>

          <ng-template #otherCondition>

            <calsoft-TopProducts [heightMobile]="productImageHeightMob" [item]="item"
              [widthMobile]="productImageWidthMob" [data]="data" [height]="productImageHeight"
              [width]="productImageWidth" [imageType]="productImageType"
              [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon" [slidesPerView]="item.customField29"
              [showCartIncremented]="showCartIncremented"
              [other]="images.customField3=='Category'?'category' :'productScope'"
              [id]="images.customField3=='Category'?images.customField4:images.customField20"
              [sortOrder]="images.customField18" [header]="images.customField15" [item]="item">
            </calsoft-TopProducts>

          </ng-template>

        </div>
      </div>
      <div *ngIf="images.customField3=='Image'">
        <div fxLayout="row wrap" [fxLayoutGap]="item.gapBetweenImage" [fxLayoutGap]="item.mobileGapBetweenImage">
          <div *ngFor="let subChild of images.subChild let j=index" class="images" fxFlex fxLayout="column">


            <calsoft-ng-optimized-image [height]="item.height" [heightMob]="item.heightMob" [navigation]="0"
              [altImage]="subChild.customField6" [catId]="subChild.catId" [navigatedUrl]="''" [customField9]="''"
              [image]="serverPath1+subChild.customField6" [i]="'0'">

            </calsoft-ng-optimized-image>

          </div>
        </div>

      </div>

      <div *ngIf="images.customField3=='TextWithImage'">
        <div class="container" [ngClass]="i>0 ? 'margin-t10' : ''" fxLayout.lt-md="column"
          [fxLayout.gt-md]="item.customField3=='right'?'row':'row-reverse'" fxLayoutGap="5px">
          <div fxFlex.gt-md="50" fxFlex.lt-md fxLayout="row">

            <div class="padding-10" fxFlexAlign="center" fxLayoutGap="5px">
              <div *ngIf="item.title!=0">
                <div *ngIf="images.customField20==1" fxLayout="row" fxLayoutAlign="start center">
                  <div class="container">
                    <h2 class="textwithImage" [style.font-size]="images.customField18"
                      [ngStyle.lt-md]="{ 'font-size': images.customField17 }" [innerHTML]="item.title">
                      <span>
                        {{images.customField19}}
                      </span>
                    </h2>
                  </div>
                </div>
                <div *ngIf="images.customField20==0" fxLayout="row" fxLayoutAlign="start center">
                  <h2 class="textwithImage" [style.font-size]="images.customField18"
                    [ngStyle.lt-md]="{ 'font-size': images.customField17 }" [innerHTML]="item.title">
                  </h2>
                </div>
              </div>
              <div>
                <p class="textwithImage" [innerHTML]="images?.customField1"> </p>
              </div>
              <div fxLayout="row">

                {{images.categoryId6}}
                <button style="color: white;" [style.border-radius]="images.customField11"
                  *ngIf="images.customField6==0" (click)="goToCategories(images.catId)" mat-flat-button
                  class="primary">{{images.customField5}}</button>
                <button style="color: white;" [style.border-radius]="images.customField11"
                  *ngIf="images.customField6==1" (click)="link(images.catId)" mat-flat-button
                  class="primary">{{images.customField5}}</button>

              </div>

            </div>
          </div>
          <div class="images" fxFlex.gt-md="50" fxFlex.lt-md fxLayout="column" fxLayoutAlign="start stretch">


            <ng-container *ngIf="images.type==2">
              <div>
                <div style="width: 100%;height:500px" ngStyle.lt-md="height:100%">
                  <iframe style="width: 100%; height: 100%;"
                    [src]="youtubeUrl+images.img | safe: 'resourceUrl'"></iframe>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="images.type==1">

              <calsoft-ng-optimized-image [altImage]="item.altImage" [catId]="'0'" [navigatedUrl]="''"
                [customField9]="''" [image]="serverPath +item.customField24" [i]="'0'">
              </calsoft-ng-optimized-image>

            </ng-container>

          </div>

        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="item.customField13==1">
  <mat-tab-group mat-align-tabs="center" (selectedTabChange)="onTabChange($event,i)">
    <mat-tab *ngFor="let images of item.images let i=index" [label]="images.customField15">
      <div *ngIf="images.customField3=='Category' || images.customField3=='ProductScope' ">
        <div *ngIf="i==index">
          <div *ngIf="item.layout=='type-2';else otherCondition">
            <calsoft-product-carousel [heightMobile]="productImageHeightMob" [item]="item"
              [widthMobile]="productImageWidthMob" [data]="data" [height]="productImageHeight"
              [width]="productImageWidth" [imageType]="productImageType"
              [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon" [slidesPerView]="item.customField29"
              [showCartIncremented]="showCartIncremented"
              [other]="images.customField3=='Category'?'category' :'productScope'"
              [id]="images.customField3=='Category'?images.customField4:images.customField20"
              [sortOrder]="images.customField18" [header]="images.customField15" [item]="item">
            </calsoft-product-carousel>

          </div>

          <ng-template #otherCondition>

            <calsoft-TopProducts [heightMobile]="productImageHeightMob" [item]="item"
              [widthMobile]="productImageWidthMob" [data]="data" [height]="productImageHeight"
              [width]="productImageWidth" [imageType]="productImageType"
              [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon" [slidesPerView]="item.customField29"
              [showCartIncremented]="showCartIncremented"
              [other]="images.customField3=='Category'?'category' :'productScope'"
              [id]="images.customField3=='Category'?images.customField4:images.customField20"
              [sortOrder]="images.customField18" [header]="images.customField15" [item]="item">
            </calsoft-TopProducts>

          </ng-template>
        </div>
      </div>
      <div *ngIf="images.customField3=='Image'">
        <div fxLayout="row wrap" [fxLayoutGap]="item.gapBetweenImage" [fxLayoutGap]="item.mobileGapBetweenImage">
          <div *ngFor="let subChild of images.subChild let j=index" class="images" fxFlex fxLayout="column">
            <!-- 
            <img [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob
            }" (click)="goToCategories(subChild.catId)" [src]="serverPath1+subChild.customField6"
              onerror="this.src='assets/images/products/noImage/no-image.png';" [alt]="subChild.customField6"> -->
            <calsoft-ng-optimized-image [height]="item.height" [heightMob]="item.heightMob" [navigation]="0"
              [altImage]="subChild.customField6" [image]="serverPath1+subChild.customField6" [i]="'0'">
            </calsoft-ng-optimized-image>

          </div>
        </div>

      </div>

      <div *ngIf="images.customField3=='TextWithImage'">
        <div class="container" [ngClass]="i>0 ? 'margin-t10' : ''" fxLayout.lt-md="column"
          [fxLayout.gt-md]="item.customField3=='right'?'row':'row-reverse'" fxLayoutGap="5px">
          <div fxFlex.gt-md="50" fxFlex.lt-md fxLayout="row">

            <div class="padding-10" fxFlexAlign="center" fxLayoutGap="5px">
              <div *ngIf="item.title!=0">
                <div *ngIf="images.customField20==1" fxLayout="row" fxLayoutAlign="start center">
                  <div class="container">
                    <h2 class="textwithImage" [style.font-size]="images.customField18"
                      [ngStyle.lt-md]="{ 'font-size': images.customField17 }" [innerHTML]="item.title">
                      <span>
                        {{images.customField19}}
                      </span>
                    </h2>
                  </div>
                </div>
                <div *ngIf="images.customField20==0" fxLayout="row" fxLayoutAlign="start center">
                  <h2 class="textwithImage" [style.font-size]="images.customField18"
                    [ngStyle.lt-md]="{ 'font-size': images.customField17 }" [innerHTML]="item.title">
                  </h2>
                </div>
              </div>
              <div>
                <p class="textwithImage" [innerHTML]="images?.customField1"> </p>
              </div>
              <div fxLayout="row">

                {{images.categoryId6}}
                <button style="color: white;" [style.border-radius]="images.customField11"
                  *ngIf="images.customField6==0" (click)="goToCategories(images.catId)" mat-flat-button
                  class="primary">{{images.customField5}}</button>
                <button style="color: white;" [style.border-radius]="images.customField11"
                  *ngIf="images.customField6==1" (click)="link(images.catId)" mat-flat-button
                  class="primary">{{images.customField5}}</button>

              </div>

            </div>
          </div>
          <div class="images" fxFlex.gt-md="50" fxFlex.lt-md fxLayout="column" fxLayoutAlign="start stretch">


            <ng-container *ngIf="images.type==2">
              <div>
                <div style="width: 100%;height:500px" ngStyle.lt-md="height:100%">
                  <iframe style="width: 100%; height: 100%;"
                    [src]="youtubeUrl+images.img | safe: 'resourceUrl'"></iframe>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="images.type==1">
              <img [src]="serverPath +item.customField24" [alt]="item.altImage || 'images' ">
            </ng-container>

          </div>

        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="item.customField13==2">
  <mat-tab-group mat-align-tabs="end" (selectedTabChange)="onTabChange($event,i)">
    <mat-tab *ngFor="let images of item.images let i=index" [label]="images.customField15">
      <div *ngIf="images.customField3=='Category' || images.customField3=='ProductScope' ">
        <div *ngIf="i==index">
          <div *ngIf="item.layout=='type-2';else otherCondition">
            <calsoft-product-carousel [heightMobile]="productImageHeightMob" [item]="item"
              [widthMobile]="productImageWidthMob" [data]="data" [height]="productImageHeight"
              [width]="productImageWidth" [imageType]="productImageType"
              [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon" [slidesPerView]="item.customField29"
              [showCartIncremented]="showCartIncremented"
              [other]="images.customField3=='Category'?'category' :'productScope'"
              [id]="images.customField3=='Category'?images.customField4:images.customField20"
              [sortOrder]="images.customField18" [header]="images.customField15" [item]="item">
            </calsoft-product-carousel>

          </div>

          <ng-template #otherCondition>

            <calsoft-TopProducts [heightMobile]="productImageHeightMob" [item]="item"
              [widthMobile]="productImageWidthMob" [data]="data" [height]="productImageHeight"
              [width]="productImageWidth" [imageType]="productImageType"
              [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon" [slidesPerView]="item.customField29"
              [showCartIncremented]="showCartIncremented"
              [other]="images.customField3=='Category'?'category' :'productScope'"
              [id]="images.customField3=='Category'?images.customField4:images.customField20"
              [sortOrder]="images.customField18" [header]="images.customField15" [item]="item">
            </calsoft-TopProducts>

          </ng-template>
        </div>
      </div>
      <div *ngIf="images.customField3=='Image'">
        <div fxLayout="row wrap" [fxLayoutGap]="item.gapBetweenImage" [fxLayoutGap]="item.mobileGapBetweenImage">
          <div *ngFor="let subChild of images.subChild let j=index" class="images" fxFlex fxLayout="column">
            <!-- <img [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob
          }" (click)="goToCategories(subChild.catId)" [src]="serverPath1+subChild.customField6"
              onerror="this.src='assets/images/products/noImage/no-image.png';" [alt]="subChild.customField6"> -->
            <calsoft-ng-optimized-image [height]="item.height" [heightMob]="item.heightMob" [navigation]="0"
              [altImage]="subChild.customField6" [image]="serverPath1+subChild.customField6" [i]="'0'">
            </calsoft-ng-optimized-image>
          </div>
        </div>

      </div>

      <div *ngIf="images.customField3=='TextWithImage'">
        <div class="container" [ngClass]="i>0 ? 'margin-t10' : ''" fxLayout.lt-md="column"
          [fxLayout.gt-md]="item.customField3=='right'?'row':'row-reverse'" fxLayoutGap="5px">
          <div fxFlex.gt-md="50" fxFlex.lt-md fxLayout="row">

            <div class="padding-10" fxFlexAlign="center" fxLayoutGap="5px">
              <div *ngIf="item.title!=0">
                <div *ngIf="images.customField20==1" fxLayout="row" fxLayoutAlign="start center">
                  <div class="container">
                    <h2 class="textwithImage" [style.font-size]="images.customField18"
                      [ngStyle.lt-md]="{ 'font-size': images.customField17 }" [innerHTML]="item.title">
                      <span>
                        {{images.customField19}}
                      </span>
                    </h2>
                  </div>
                </div>
                <div *ngIf="images.customField20==0" fxLayout="row" fxLayoutAlign="start center">
                  <h2 class="textwithImage" [style.font-size]="images?.customField18"
                    [ngStyle.lt-md]="{ 'font-size': images?.customField17 }" [innerHTML]="item.title">
                  </h2>
                </div>
              </div>
              <div>
                <p class="textwithImage" [innerHTML]="images?.customField1"> </p>
              </div>
              <div fxLayout="row">
                <button style="color: white;" [style.border-radius]="images.customField11"
                  *ngIf="images.customField6==0" (click)="goToCategories(images.catId)" mat-flat-button
                  class="primary">{{images.customField5}}</button>
                <button style="color: white;" [style.border-radius]="images.customField11"
                  *ngIf="images.customField6==1" (click)="link(images.catId)" mat-flat-button
                  class="primary">{{images.customField5}}</button>

              </div>

            </div>
          </div>
          <div class="images" fxFlex.gt-md="50" fxFlex.lt-md fxLayout="column" fxLayoutAlign="start stretch">


            <ng-container *ngIf="images.type==2">
              <div>
                <div style="width: 100%;height:500px" ngStyle.lt-md="height:100%">
                  <iframe style="width: 100%; height: 100%;"
                    [src]="youtubeUrl+images.img | safe: 'resourceUrl'"></iframe>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="images.type==1">

              <calsoft-ng-optimized-image [altImage]="item.altImage || 'images'"
                [image]="serverPath +item.customField24" [i]="'0'">

              </calsoft-ng-optimized-image>
            </ng-container>



          </div>

        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>


<div *ngIf="item.customField13==3">

  <div class="newhead">
    <h3><calsoft-editor-text [text]="item.title" [align]="item.customField34"  [style.font-size]="images?.customField18"
      [ngStyle.lt-md]="{ 'font-size': images?.customField17 }"> 
      </calsoft-editor-text></h3>
  </div>

  <div class="paragraph">
    <p>
      <calsoft-editor-text [data]="data" [text]="item.customField6"   [style.font-size]="images?.customField37"
      [ngStyle.lt-md]="{ 'font-size': images?.customField38 }" [align]="item.customField34">
      </calsoft-editor-text>
    </p>
  </div>

  <div class="padding-10">

  </div>

  <div fxLayout="row" fxLayoutAlign="space-around  center">
    <div fxLayout="row wrap" fxLayoutAlign="center center" [fxLayoutGap]="item.gapBetweenImage"
      [fxLayoutGap.xs]="item.mobileGapBetweenImage">
      <div fxLayout="column" *ngFor="let images of item.images; let i=index" fxLayoutGap="8px" fxFlex class="image-row">
        <div fxLayout="row">
          <button class="radius padding-10" [ngClass]="{'highlighted': i === defaultIndex}" mat-stroked-button
            (click)="toggleSecondDiv(i)">{{images.customField15}}</button>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" style="margin-top:1rem">
    <div fxFlex="100" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-around  center">


        <div fxLayout="row wrap" [fxLayoutGap]="item.gapBetweenImage" [fxLayoutGap.xs]="item.mobileGapBetweenImage">
          <div *ngFor="let subChild of item.images[defaultIndex].subChild let j=index" class="images" fxFlex="auto"
            fxLayout="column">

            <calsoft-ng-optimized-image style="width:fit-content" fxLayoutAlign="space-around  center"
              [height]="item.height" [heightMob]="item.heightMob" [navigation]="0" [altImage]="subChild.customField6"
              [catId]="subChild.catId" [navigatedUrl]="''" [customField9]="''"
              [image]="serverPath1+subChild.customField6" [i]="'0'">
            </calsoft-ng-optimized-image>

          </div>
        </div>


      </div>
    </div>
  </div>
</div>

<div *ngIf="item.customField13==4">

  <div class="newhead">
    <h3><calsoft-editor-text [text]="item.title" [align]="item.customField34" [style.font-size]="images?.customField18"
      [ngStyle.lt-md]="{ 'font-size': images?.customField17 }">
      </calsoft-editor-text></h3>
  </div>

  <div class="paragraph">
    <p>
      <calsoft-editor-text [data]="data" [text]="item.customField6" [align]="item.customField34"
      [style.font-size]="images?.customField37"
      [ngStyle.lt-md]="{ 'font-size': images?.customField38 }">
      </calsoft-editor-text>
    </p>
  </div>

  <div class="padding-10">

  </div>

  <div fxLayout="row" [fxLayoutAlign]="item.customField5">
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxLayout="column" *ngFor="let images of item.images; let i=index" fxLayoutGap="8px" fxFlex class="image-row">
        <div fxLayout="row">
          <span style="cursor: pointer;" class="padding-10" [ngClass]="{'highlighted': i === defaultIndex}"
            (click)="toggleSecondDiv(i)">{{images.customField15}}</span>
          <!-- <ng-container *ngIf="i !== item.images.length - 1 || images.customField15.endsWith('|')">
            <span class="padding-10">|</span>
          </ng-container> -->
        </div>
      </div>

    </div>
  </div>
  <div fxLayout="row" style="margin-top:1rem">
    <div fxFlex="100" fxLayout="column">
      <div fxLayout="row" [fxLayoutAlign]="item.customField5">
        <div fxLayout="row wrap">
          <div *ngFor="let subChild of item.images[defaultIndex].subChild let j=index" class="images" fxFlex="auto"
            fxLayout="column">
            <p [fxLayoutGap]="item.gapBetweenImage" [innerHTML]="subChild.customField19"
              [fxLayoutGap.xs]="item.mobileGapBetweenImage" class="padding-10">
              <!-- <calsoft-editor-text  [align]="item.customField34" [data]="data" [text]="subChild.customField19">
                </calsoft-editor-text> -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div *ngIf="item.customField13==5">

  <div class="newhead">
    <h3><calsoft-editor-text [text]="item.title" [align]="item.customField34" [style.font-size]="images?.customField18"
      [ngStyle.lt-md]="{ 'font-size': images?.customField17 }">
      </calsoft-editor-text></h3>
  </div>

  <div class="paragraph">
    <p>
      <calsoft-editor-text [data]="data" [text]="item.customField6" [align]="item.customField34" 
      [style.font-size]="images?.customField37"
      [ngStyle.lt-md]="{ 'font-size': images?.customField38 }">
      </calsoft-editor-text>
    </p>
  </div>

  <div class="padding-10">

  </div>

  <div fxLayout="row" fxLayoutAlign="space-around  center">
    <div fxLayout="row wrap" fxLayoutAlign="center center" [fxLayoutGap]="item.gapBetweenImage"
      [fxLayoutGap.xs]="item.mobileGapBetweenImage">
      <div fxLayout="column" *ngFor="let images of item.images; let i=index" fxLayoutGap="8px" fxFlex class="image-row">
        <div fxLayout="row">
          <button class="radius padding-10" [ngClass]="{'highlighted': i === defaultIndex}" mat-stroked-button
            (click)="scrollDown(images.customField16,i)">
            <mat-icon *ngIf="images.customField14">{{images.customField14}}</mat-icon> 
            {{images.customField15}}</button>
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="item.customField13==6">

  <div class="newhead">
    <h3><calsoft-editor-text [text]="item.title" [align]="item.customField34" [style.font-size]="images.customField18"
      [ngStyle.lt-md]="{ 'font-size': images.customField17 }">
      </calsoft-editor-text></h3>
  </div>

  <div class="paragraph">
    <p>
      <calsoft-editor-text [data]="data" [text]="item.customField6" [align]="item.customField34"
      [style.font-size]="images.customField37"
      [ngStyle.lt-md]="{ 'font-size': images.customField38 }">
      </calsoft-editor-text>
    </p>
  </div>

  <div class="padding-10">

  </div>

  <div fxHide fxShow.gt-sm>
    <div fxLayout="row">
      <div fxFlex="25" fxLayout="column">
        <div [fxLayoutGap]="item.gapBetweenImage" [fxLayoutGap.xs]="item.mobileGapBetweenImage">
          <!-- Loop through images and stack buttons in a column -->
          <div fxLayout="column" *ngFor="let images of item.images; let i = index">
            <button class="radius padding-10" [ngClass]="{'highlighted': i === defaultIndex}" mat-stroked-button
              (click)="toggleSecondDiv(i)" fxFlex="none" style="margin-bottom: 10px;">
              {{images.customField15}}
            </button>
          </div>
        </div>
      </div>

      <div fxFlex="75" style="margin-top:1rem">
        <div fxFlex="100" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-around center">
            <div fxLayout="row wrap" [fxLayoutGap]="item.gapBetweenImage" [fxLayoutGap.xs]="item.mobileGapBetweenImage">
              <div *ngFor="let subChild of item.images[defaultIndex].subChild; let j = index" class="images"
                fxFlex="auto" fxLayout="column">
                <calsoft-ng-optimized-image style="width:fit-content" fxLayoutAlign="space-around center"
                  [height]="item.height" [heightMob]="item.heightMob" [navigation]="0"
                  [altImage]="subChild.customField6" [catId]="subChild.catId" [navigatedUrl]="''" [customField9]="''"
                  [image]="serverPath1 + subChild.customField6" [i]="'0'">
                </calsoft-ng-optimized-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxHide fxShow.lt-sm>
    <div fxLayout="row" fxLayoutAlign="space-around  center">
      <div fxLayout="row wrap" fxLayoutAlign="center center" [fxLayoutGap]="item.gapBetweenImage"
        [fxLayoutGap.xs]="item.mobileGapBetweenImage">
        <div fxLayout="column" *ngFor="let images of item.images; let i=index" fxLayoutGap="8px" fxFlex class="image-row">
          <div fxLayout="row">
            <button class="radius padding-10" [ngClass]="{'highlighted': i === defaultIndex}" mat-stroked-button
              (click)="toggleSecondDiv(i)">{{images.customField15}}</button>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" style="margin-top:1rem">
      <div fxFlex="100" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-around  center">
  
  
          <div fxLayout="row wrap" [fxLayoutGap]="item.gapBetweenImage" [fxLayoutGap.xs]="item.mobileGapBetweenImage">
            <div *ngFor="let subChild of item.images[defaultIndex].subChild let j=index" class="images" fxFlex="auto"
              fxLayout="column">
  
              <calsoft-ng-optimized-image style="width:fit-content" fxLayoutAlign="space-around  center"
                [height]="item.height" [heightMob]="item.heightMob" [navigation]="0" [altImage]="subChild.customField6"
                [catId]="subChild.catId" [navigatedUrl]="''" [customField9]="''"
                [image]="serverPath1+subChild.customField6" [i]="'0'">
              </calsoft-ng-optimized-image>
  
            </div>
          </div>
  
  
        </div>
      </div>
    </div>    
  </div>


</div>