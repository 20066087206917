<div *ngIf="subscribed&&type!='wishonly';else subscribedTem">

    <div fxFlex="100">
        <button fxFlex style="font-weight: 500; letter-spacing: 1px;" class="add-to-cart-button primary" mat-flat-button
            matTooltip="Add to cart" (click)="goToCourse(product)">
            Go To Course
        </button>
    </div>
</div>

<ng-template #subscribedTem>
    <div *ngIf="type=='miniCart'">
        <div
            *ngIf="!((product.qty <= 0 || product.isInStock == 0) && (product.manageStock == 1 || product.isInStock == 0))">
            <div class="product-action" fxLayoutAlign="center center">
                <button *ngIf="qty<=0"
                    (click)="addToCart(product, 'cart', product.minSaleQty == 0 ? 1 : product.minSaleQty)" mat-button
                    class="addbtn">
                    <span>Add</span>
                </button>
            </div>

            <div fxFlex fxLayout="row" style="padding: 8px;" *ngIf="qty>0" class="qtys1">

                <button class="addbtn"
                    (click)="addToCartProduct(product,'cart',product.minSaleQty==0?-1:-product.minSaleQty)">
                    <span class="material-icons">remove</span>
                </button>
                <button style="padding: 8px;" class="addbtn">
                    {{qty}}
                </button>
                <button class="addbtn" (click)="addToCart(product,'cart',product.minSaleQty==0?1:product.minSaleQty)">
                    <span class="material-icons">add</span>
                </button>

            </div>

        </div>
    </div>

    <div *ngIf="type!='miniCart'&&type!='swiggyCart'">
        <div *ngIf="type=='wishonly'; else elseBlock" fxLayout="row" fxLayout.xs="column">

            <button *ngIf="!wishMapDisplay" color="warn" mat-icon-button matTooltip="Add to wishlist"
                (click)="addToWishList(product)">
                <mat-icon>favorite_border</mat-icon>
            </button>
            <button *ngIf="wishMapDisplay" (click)="addToWishList(product)" mat-icon-button color="warn"
                aria-label="wishlist">
                <mat-icon>favorite</mat-icon>
            </button>

        </div>
    </div>


    <div *ngIf="type=='swiggyCart'">
        <div class="swiggyCartcartbtn"
            *ngIf="!((product.qty <= 0 || product.isInStock == 0) && (product.manageStock == 1 || product.isInStock == 0))">
            <div>
                <button *ngIf="qty<=0"
                    (click)="addToCartPartner(product, 'cart', product.minSaleQty == 0 ? 1 : product.minSaleQty)"
                    mat-button class="swiggyCartcartbtn">ADD</button>
            </div>

            <div fxFlex fxLayout="row" *ngIf="qty>0" class="qtys1">

                <button style="background-color: white;" class="cart-button lt" fxFlex="35" *ngIf="qty > 0"
                    (click)="addToCartPartner(product,'cart',product.minSaleQty==0?-1:-product.minSaleQty)">
                    <span style="color: black;" class="material-icons">remove</span>
                    <!-- Use "remove" for minus icon -->
                </button>

                <button style="background-color: white;" class="cart-button center" fxFlex="30"
                    matTooltip="Add to cart">
                    <span style="color: black;"> {{qtyFormControl.value}} </span>
                </button>

                <button style="background-color: white;" fxFlex="35" class="cart-button rt"
                    (click)="partneraddToCart(product,'cart',product.minSaleQty==0?1:product.minSaleQty)">
                    <span style="color: black;" class="material-icons">add</span> <!-- Use "add" for plus icon -->
                </button>

            </div>

        </div>
    </div>



    <ng-template #elseBlock>


        <div *ngIf="type=='subscription'&&type!='miniCart'&&type!='swiggyCart'">

            <!-- {{qtyFormControl.value}} -->
            <div fxLayoutGap="10px">
                <button fxFlex="100" (click)="addToCart(product, 
            'buyNow', subscription.totalQty)" fxFlex="100" class="btn-product btn-cart primary">
                    <i class="material-icons" style="font-size: 21px;margin-right: 16px;">add_shopping_cart
                    </i> Confirm Subscription
                </button>

                <ng-container *ngIf="data.whatsAppButtonEnableProduct==1
        &&type!='cashfInhand'">
                    <button [fxFlex]="showCartIcon==1?'100':'100'" fxLayoutAlign="center center" fxLayout.lt-sm="100"
                        style="background: #e7f3ec;" color="primary" mat-flat-button mat- type="button"
                        (click)="whatsapp()">

                        <mat-icon svgIcon="whatsapp" style="height: 100%;">

                        </mat-icon>

                    </button>
                    &nbsp;&nbsp;
                </ng-container>

            </div>
        </div>

        <div *ngIf="type=='cartIcon'&&type!='miniCart'&&type!='swiggyCart'">

            <div
                *ngIf="!((product.qty <= 0 || product.isInStock == 0) && (product.manageStock == 1 || product.isInStock == 0))">
                <button fxFlex="90" (click)="addToCart(product, 
            'cart', product.minSaleQty == 0 ? 1 : product.minSaleQty)" mat-icon-button>
                    <mat-icon [style.color]="data.carouselIconColor">shopping_cart</mat-icon>

                    <span *ngIf="qtyFormControl.value>0" class="cart-items-count1">
                        {{ qtyFormControl.value }}</span>

                </button>



            </div>
        </div>


        <div *ngIf="type=='newWishIcon'&&type!='miniCart'&&type!='swiggyCart'">

            <button fxFlex="90" *ngIf="!wishMapDisplay" mat-icon-button matTooltip="Add to wishlist"
                (click)="addToWishList(product)">
                <mat-icon [style.color]="data.carouselIconColor">favorite_border</mat-icon>
            </button>
            <button *ngIf="wishMapDisplay" (click)="addToWishList(product)" mat-icon-button aria-label="wishlist">
                <mat-icon [style.color]="data.carouselIconColor">favorite</mat-icon>
            </button>

        </div>

        <div *ngIf="type=='newAddTocart'&&type!='subscription'&&type!='miniCart'&&type!='swiggyCart'">
            <div
                *ngIf="!((product.qty <= 0 || product.isInStock == 0) && (product.manageStock == 1 || product.isInStock == 0))">
                <div class="product-action" fxLayoutAlign="center center">
                    <button (click)="addToCart(product, 'cart', product.minSaleQty == 0 ? 1 : product.minSaleQty)"
                        *ngIf="(qty <= 0 && showCartIcon == 1) || (showCartIcon == 1 && showCartIncremented == 0)"
                        fxFlex="100" class="btn-product btn-cart primary">
                        <i class="material-icons" style="font-size: 21px;margin-right: 16px;">add_shopping_cart
                        </i>{{text}}
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="type=='type4addtocart'&&type!='subscription'&&type!='miniCart'&&type!='swiggyCart'">
            <div
                *ngIf="!((product.qty <= 0 || product.isInStock == 0) && (product.manageStock == 1 || product.isInStock == 0))">
                <div class="product-action" fxLayoutAlign="center center">
                    <button fxFlex style="font-weight: 500; letter-spacing: 1px;" class="add-to-cart-button primary"
                        *ngIf="(qty <= 0 && showCartIcon == 1) || (showCartIcon == 1 && showCartIncremented == 0)"
                        mat-flat-button matTooltip="Add to cart" (mouseenter)="onMouseEnter()"
                        (mouseleave)="onMouseLeave()"
                        (click)="addToCart(product, 'cart', product.minSaleQty == 0 ? 1 : product.minSaleQty)">
                        {{text}}
                    </button>
                </div>
                <div fxLayoutAlign="space-between center">
                    <div fxLayout="row">
                        <div fxLayout="column" class="padding-10" style="cursor: pointer;">
                            <button *ngIf="!wishMapDisplay" color="warn" mat-icon-button matTooltip="Add to wishlist"
                                (click)="addToWishList(product)">
                                <mat-icon>favorite_border</mat-icon>
                                <span style="cursor: pointer;" (click)="addToWishList(product)" class="padding-10">
                                    &nbsp; &nbsp; {{ addToWishlistText }}
                                </span>

                            </button>
                            <button *ngIf="wishMapDisplay" (click)="addToWishList(product)" mat-icon-button color="warn"
                                aria-label="wishlist">
                                <mat-icon>favorite</mat-icon>
                                <span style="cursor: pointer;" (click)="addToWishList(product)" class="padding-10">
                                    &nbsp; &nbsp; {{ addToWishlistText }}
                                </span>

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="type=='type5addtocart'&&type!='subscription'">
            <div
                *ngIf="!((product.qty <= 0 || product.isInStock == 0) && (product.manageStock == 1 || product.isInStock == 0))">
                <a title="Wishlist">
                    <div class="icon-containers">
                        <button *ngIf="!wishMapDisplay" color="warn" mat-icon-button matTooltip="Add to wishlist"
                            (click)="addToWishList(product)">
                            <mat-icon class="wish">favorite_border</mat-icon>
                        </button>
                        <button *ngIf="wishMapDisplay" (click)="addToWishList(product)" mat-icon-button color="warn"
                            aria-label="wishlist">
                            <mat-icon class="wish">favorite</mat-icon>
                        </button>
                        <!-- <mat-icon class="wish">favorite</mat-icon> -->
                    </div>
                </a>
                <a title="AddTocart">
                    <div class="icon-containers">
                        <button (click)="addToCart(product, 
        'cart', product.minSaleQty == 0 ? 1 : product.minSaleQty)" mat-icon-button>
                            <mat-icon class="wish">shopping_cart</mat-icon>

                            <span *ngIf="qtyFormControl.value>0" class="cart-items-count1">
                                {{ qtyFormControl.value }}</span>

                        </button>
                        <!-- <mat-icon class="wish">shopping_cart</mat-icon> -->
                    </div>

                </a>
            </div>
        </div>


        <div fxLayout="row" *ngIf="type!='cartIcon'&&type!='newWishIcon';" fxLayout.xs="column" class="text-muted">
            <div *ngIf="(type=='all' || type=='wish')" class="qty" fxLayout="row" fxLayoutAlign="start center"
                fxLayoutGap="10px">
                <div fxLayoutAlign="start center">
                    <span *ngIf="type!='wish'">Quantity</span>
                </div>
                <div class="quantity" fxLayout="row">


                    <ng-container>
                        <div matRipple class="dec" fxFlex="33" fxLayoutAlign="center center">
                            <button (click)="decrementass(product.controls.qty.value)"
                                [disabled]='product.controls.qty.value <= 1 || disableControl==0' mat-icon-button>
                                <mat-icon [ngClass]="{'disable': product.controls.qty.value <= 1}"
                                    class="material-icons-outlined">
                                    keyboard_arrow_left
                                </mat-icon>
                            </button>
                        </div>
                    </ng-container>
                    <input numbersOnly (keyup.enter)="updateQty(qtyInput.value,$event,'all')"
                        (blur)="updateQty(qtyInput.value,$event,'all')" [formControl]="qtyInput" numbersOnly
                        style="text-align: center;width:50px;" class="dec inc inQty form-line-active " type="text">

                    <!-- </div> -->
                    <div matRipple (click)="increment(product.controls.qty.value,'all')" class="inc" fxFlex="33"
                        fxLayoutAlign="center center">

                        <button [disabled]='disableControl==0' mat-icon-button>
                            <mat-icon class="material-icons-outlined">
                                keyboard_arrow_right
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="(type=='cashInhand')" class="qty" fxLayout="column" fxLayoutAlign="start center"
                fxLayoutGap="10px">

                <div class="quantity" fxLayout="row">

                    <ng-container>
                        <div matRipple class="dec" fxFlex="33" fxLayoutAlign="center center">
                            <button (click)="decrementass(product.controls.stockInHand.value)"
                                [disabled]='product.controls.stockInHand.value <= 0 || disableControl==0'
                                mat-icon-button>


                                <mat-icon [ngClass]="{'disable': product.controls.stockInHand.value <= 1}"
                                    class="material-icons-outlined">
                                    keyboard_arrow_left
                                </mat-icon>
                            </button>
                        </div>
                    </ng-container>



                    <input numbersOnly (keyup.enter)="updateQty(stockInHandInput.value,$event,'cashInhand')"
                        (blur)="updateQty(stockInHandInput.value,$event,'cashInhand')" [formControl]="stockInHandInput"
                        numbersOnly style="text-align: center;width:50px;" class="dec inc inQty form-line-active "
                        type="text">


                    <div matRipple (click)="increment(product.controls.stockInHand.value,'cashInhand')" class="inc"
                        fxFlex="33" fxLayoutAlign="center center">

                        <button [disabled]='disableControl==0' mat-icon-button>
                            <mat-icon class="material-icons-outlined">
                                keyboard_arrow_right
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>




            <div fxLayout="row" fxLayout.lt-sm="column" fxFlex.gt-xs
                *ngIf="type!='wish'&&type!='addtocart'&&type!='navigationButton'&&type!='navigationButton' &&type!='subscription' && type!='coursecart'">

                <ng-container
                    *ngIf="!((product.qty <= 0||product.isInStock==0) && (product.manageStock == 1||product.isInStock==0))&&type!='newAddTocart'&&type!='type4addtocart'&&type!='type5addtocart'&&type!='miniCart'&&type!='booknow'&&type!='swiggyCart'">

                    <ng-container *ngIf="data.whatsAppButtonEnableProduct==1&&type!='cashInhand'">
                        <button [fxFlex]="product.type=='Subscription'?'100':showCartIcon==1?'5':'100'"
                            fxLayoutAlign="center center" fxLayout.lt-sm="100" style="background: #e7f3ec;"
                            color="primary" mat-flat-button mat- type="button" (click)="whatsapp()">
                            <mat-icon svgIcon="whatsapp" style="height: 100%;">

                            </mat-icon>
                            <!-- <img style="height: 24px;" src="assets/icons/whatsapp.svg" alt=""> -->

                        </button>
                        &nbsp;&nbsp;
                    </ng-container>

                </ng-container>


                <ng-container fxFlex="100" *ngIf="product.type!='Subscription'">


                    <ng-container
                        *ngIf="((product.qty <= 0||product.isInStock==0) && (product.manageStock == 1||product.isInStock==0))&&type!='newAddTocart'&&type!='type5addtocart'&&type!='type4addtocart'&&type!='miniCart'&&type!='booknow'&&type!='swiggyCart'">

                        <ng-container *ngIf="data.whatsAppButtonEnableProduct==1
                &&type!='cashInhand'">
                            <button [fxFlex]="showCartIcon==1?'100':'100'" fxLayoutAlign="center center"
                                fxLayout.lt-sm="100" style="background: #e7f3ec;" color="primary" mat-flat-button mat-
                                type="button" (click)="whatsapp()">

                                <mat-icon svgIcon="whatsapp" style="height: 100%;">

                                </mat-icon>

                            </button>
                            &nbsp;&nbsp;
                        </ng-container>

                    </ng-container>


                    <ng-container
                        *ngIf="!((product.qty <= 0 || product.isInStock == 0) && (product.manageStock == 1 || product.isInStock == 0))&&type!='newAddTocart'&&type!='type4addtocart'&&type!='type5addtocart'&&type!='miniCart'&&type!='swiggyCart'">
                        
                        <div [fxFlex]="showCartIncremented === 0 ? '' : 0" *ngIf="data.addToCartType === 0">
                            <button style="color: #ffffff; font-weight: 500; letter-spacing: 1px;" fxFlex="100"
                                class="primary"
                                *ngIf="(qty <= 0 && showCartIcon == 1) || (showCartIcon == 1 && showCartIncremented == 0)"
                                mat-flat-button matTooltip="Add to cart"
                                (click)="addToCart(product, 'cart', product.minSaleQty == 0 ? 1 : product.minSaleQty)">
                                {{text}}
                            </button>
                        </div>

                        <div [fxFlex]="showCartIncremented === 0 ? '' : 0" *ngIf="data.addToCartType === 1">
                            <button fxFlex style="font-weight: 500; letter-spacing: 1px;"
                                class="add-to-cart-button primary"
                                *ngIf="(qty <= 0 && showCartIcon == 1) || (showCartIcon == 1 && showCartIncremented == 0)"
                                mat-flat-button matTooltip="Add to cart" (mouseenter)="onMouseEnter()"
                                (mouseleave)="onMouseLeave()"
                                (click)="addToCart(product, 'cart', product.minSaleQty == 0 ? 1 : product.minSaleQty)">
                                {{text}}
                            </button>
                        </div>

                        <div [fxFlex]="showCartIncremented === 0 ? '' : 0" *ngIf="data.addToCartType === 2">
                            <button fxFlex style="font-weight: 500; letter-spacing: 1px;"
                                class="add-to-cart-button primary"
                                *ngIf="(qty <= 0 && showCartIcon == 1) || (showCartIcon == 1 && showCartIncremented == 0)"
                                mat-flat-button matTooltip="Add to cart" (mouseenter)="onMouseEnter()"
                                (mouseleave)="onMouseLeave()"
                                (click)="addToCart(product, 'cart', product.minSaleQty == 0 ? 1 : product.minSaleQty)">
                                Buy Now
                            </button>
                        </div>

                    </ng-container>

                    <div fxFlex="100"
                        *ngIf="qty > 0 && (showCartIncremented === 1 && showCartIcon === 1)&&!courseProduct">
                        <div fxFlex="100" fxLayout="row">
                            <span fxFlex="100" fxFlex.xs="100" *ngIf="data.addToCartSymbolType==0">
                                <button class="cart-button lt" color="primary" fxFlex="35" *ngIf="qty >0"
                                    (click)="addToCartProduct(product,'cart',product.minSaleQty==0?-1:-product.minSaleQty)">
                                    <mat-icon>keyboard_arrow_left</mat-icon>
                                </button>

                                <button class="cart-button center " fxFlex="30" color="primary"
                                    matTooltip="Add to cart">
                                    {{qtyFormControl.value}}
                                </button>
                                <button fxFlex="35" class="cart-button rt" color="primary"
                                    (click)="addToCart(product,'cart',product.minSaleQty==0?1:product.minSaleQty)">

                                    <span class="material-icons">
                                        keyboard_arrow_right
                                    </span>
                                </button>
                            </span>

                            <span fxFlex="100" fxFlex.xs="100" *ngIf="data.addToCartSymbolType==1">
                                <button class="cart-button lt" color="primary" fxFlex="35" *ngIf="qty > 0"
                                    (click)="addToCartProduct(product,'cart',product.minSaleQty==0?-1:-product.minSaleQty)">
                                    <span class="material-icons">remove</span> <!-- Use "remove" for minus icon -->
                                </button>

                                <button class="cart-button center" fxFlex="30" color="primary" matTooltip="Add to cart">
                                    {{qtyFormControl.value}}
                                </button>

                                <button fxFlex="35" class="cart-button rt" color="primary"
                                    (click)="addToCart(product,'cart',product.minSaleQty==0?1:product.minSaleQty)">
                                    <span class="material-icons">add</span> <!-- Use "add" for plus icon -->
                                </button>

                            </span>
                        </div>
                    </div>
                    <div fxFlex="100" *ngIf="courseProduct&&qty > 0">
                        <button fxFlex="100" style="color: #ffffff; font-weight: 500; letter-spacing: 1px;" fxFlex="100"
                            class="primary" mat-flat-button matTooltip="Add to cart" (click)="gotoCart()">
                            {{textbtn}}
                        </button>
                    </div>

                </ng-container>




            </div>

        </div>
        <div *ngIf="product?.availibilityCount == 0 && type=='all'" class="bg-warn p-1 mt-2">
            Sorry, this item is unavailable. Please choose a different one.
        </div>

        <div *ngIf="type=='addtocheckout'">

        </div>

        <div *ngIf="type=='addtocart'">
            <div style="padding: 5px" class="buyCart" fxLayout="row" fxLayoutGap="5px">
    
                <div  fxFlex *ngIf="data.addToCartType !== 2 &&  qty<=0 ">
 
                <button fxFlex="" style="color:#ffffff" class="primary" fxFlex *ngIf="qty<=0"
                    (click)="addToCart(product,'cart',product.minSaleQty==0?1:product.minSaleQty)" class="primary"
                    mat-flat-button>
                    <span>{{text}}</span>
    
                </button>
                </div>
                <div *ngIf="data.addToCartType == 2 && qty <= 0">
                    <button style="color:#ffffff" class="primary" fxFlex *ngIf="qty<=0"
                        (click)="addToCart(product,'cart',product.minSaleQty==0?1:product.minSaleQty)" 
                        class="primary"
                        mat-flat-button>
                        <span>Buy Now</span>
                    </button>
                </div>
                <div *ngIf="data.addToCartType == 2 && qty > 0">
                    <button style="color:#ffffff" class="primary" fxFlex  
                        class="primary" (click)="gotoCart()"
                        mat-flat-button>
                        <span>Go To Checkout</span>
                    </button>
                </div>
    
                <!-- <div  fxFlex="100" *ngIf="courseProduct&&qty <= 0">
    
                    <button fxFlex="100" style="color: #ffffff; font-weight: 500; letter-spacing: 1px;" fxFlex="100"
                        class="primary"
                        mat-flat-button matTooltip="Add to cart"
                        (click)="gotoCart()">
                        Go To Cartasas
                    </button>
                </div> -->
    
    
                <div fxFlex fxLayout="row" *ngIf="data.addToCartSymbolType==0&&qty>0&&!courseProduct" class="qtys1">
    
                    <button class="cart-button lt" color="primary" fxFlex="35"
                        (click)="addToCartProduct(product,'cart',product.minSaleQty==0?-1:-product.minSaleQty)">
    
                        <mat-icon>keyboard_arrow_left</mat-icon>
                    </button>
                    <button class="cart-button center " fxFlex="30" color="primary" color="primary">
                        {{qty}}
                    </button>
                    <button fxFlex="35" class="cart-button rt" color="primary"
                        (click)="addToCart(product,'cart',product.minSaleQty==0?1:product.minSaleQty)">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
    
                </div>
    
                <div fxFlex fxLayout="row" *ngIf="data.addToCartSymbolType==1&&qty>0&&!courseProduct" class="qtys1">
    
                    <button class="cart-button lt" color="primary" fxFlex="35"
                        (click)="addToCartProduct(product,'cart',product.minSaleQty==0?-1:-product.minSaleQty)">
    
                        <span class="material-icons">remove</span>
                    </button>
                    <button class="cart-button center " fxFlex="30" color="primary" color="primary">
                        {{qty}}
                    </button>
                    <button fxFlex="35" class="cart-button rt" color="primary"
                        (click)="addToCart(product,'cart',product.minSaleQty==0?1:product.minSaleQty)">
                        <span class="material-icons">add</span>
                    </button>
    
                </div>
    
    
                <div  fxFlex="100" *ngIf="courseProduct&&qty > 0 && data.addToCartType !== 2">
    
                    <button fxFlex="100" style="color: #ffffff; font-weight: 500; letter-spacing: 1px;" fxFlex="100"
                        class="primary"
                        mat-flat-button matTooltip="Add to cart"
                        (click)="gotoCart()">
                        Go To Cart
                    </button>
                </div>
    
                <button *ngIf="!courseProduct" color="accent" fxFlex
                    (click)="addToCart(product,'buyNow',product.minSaleQty==0?1:product.minSaleQty)" mat-flat-button>
                    <span>BUY NOW</span>
    
    
                </button>
    
            </div>
    
    
    
            <div fxHide fxShow-gt-sm fxFlex.sm="0" fxFlex="25" *ngIf="product?.discountPricePerUnit >50">
                <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="3px">
                    <img class="imgSize" src="assets/images/others/gift.png" alt="">
                    <span class="fStyle">Assured free gift with this product View gifts</span>
                </div>
            </div>
        </div>

        <div *ngIf="type=='coursecart'">
            <div class="buyCart" fxLayout="row" fxLayoutGap="5px">

                <button style="background-color: green; color: white; height: 50px;" fxFlex
                    (click)="addToCart(product,'buyNow',product.minSaleQty==0?1:product.minSaleQty)" mat-flat-button>
                    <span>BUY NOW</span>
                </button>
            </div>
        </div>

        <div fxLayout="column" *ngIf="type=='navigationButton'">
            <div fxLayout="row" class="links" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div fxFlex="50" fxFlex.xs="100" *ngFor="let buttonObject of buttonArray; let i = index">
                    <button style="color:#ffffff;font-weight: 500; letter-spacing: 1px;" mat-flat-button class="primary"
                        fxFlex (click)="link(buttonObject)">
                        {{buttonObject.name}}</button>
                </div>
            </div>

        </div>

    </ng-template>
</ng-template>