
  <div *ngIf="item.customField10!='Type 3'" class="container" fxHide fxShow.gt-sm>
    <!-- Left Section -->
    <div class="left-section" fxFlex="40">
      <div fxLayout="row" fxLayoutAlign="center" class="centered-content">
        <div fxLayout="column" class="sticky-content">
          <span class="section-title">
            <calsoft-text-editor [data]="data" [text]="item?.title" style="line-height: normal;"
              [ngStyle.gt-md]="{'font-size': item.customField33 }" [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
            </calsoft-text-editor>
          </span>
          <span class="description">
            <calsoft-text-editor [data]="data" [text]="item?.customField20"
              [ngStyle.gt-md]="{'font-size': item.customField21 }" [ngStyle.lt-md]="{ 'font-size': item.customField22 }">
            </calsoft-text-editor>
  
          </span>
        </div>
      </div>
    </div>
  
    <!-- Right Section -->
    <div class="right-section" fxFlex="60" contentAnim [aniName]="item.customField36">

      <div class="item" *ngFor="let section of images">
        <div class="section" fxLayout.lt-md="column">
          <div class="image-container">
            <img [attr.height]="section.customField23" [attr.width]="section.customField24"
              [src]="serverPath1 + section.customField8" [alt]="section?.alt" />
          </div>
          <div class="content-container">
            <div class="text-content">
              <span class="section-title-two">
                <calsoft-text-editor [data]="data" [text]="section.customField6"
                  [ngStyle.gt-md]="{'font-size': item.customField27 }">
                </calsoft-text-editor></span>
              <span class="description">
                <calsoft-text-editor [data]="data" [text]="section.customField7"
                  [ngStyle.gt-md]="{'font-size': item.customField29 }">
                </calsoft-text-editor></span>
            </div>
          </div>
        </div>
      </div>

  
    </div>
  </div>
  <div *ngIf="item.customField10!='Type 3'" fxLayout="column" fxHide fxShow.lt-sm>
    <div fxLayout="column" fxLayoutAlign="center center" contentAnim [aniName]="item.customField36">
      <div fxLayout="row" fxLayoutAlign="center center" fxFlex="80" style="width: 100%;">
        <div fxLayout="column" fxLayoutAlign="center center" style="padding: 0 10%;">
  
          <span style="text-align: center;margin-bottom: 15px">
            <calsoft-text-editor [data]="data" [text]="item?.title" [ngStyle.lt-md]="{'font-size': item.customField34 }">
            </calsoft-text-editor></span>
          <span style="text-align: center;margin-bottom: 20px">
            <calsoft-text-editor [data]="data" [text]="item?.customField20"
              [ngStyle.lt-md]="{'font-size': item.customField22 }">
            </calsoft-text-editor>
          </span>
        </div>
      </div>
    </div>
  
    <div fxLayout="column" fxLayoutAlign="center center" contentAnim [aniName]="item.customField36">
   
      <div *ngFor="let section of images" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="90" style="padding: 0 10%;">
          <div>
            <img [attr.height]="item.customField25" [attr.width]="item.customField26"
              [src]="serverPath1 + section.customField8" [alt]="section?.alt" />
          </div>
          <div style="display: flex; flex-direction: column; align-items: center;">
            <span style="text-align: center;margin-bottom: 15px">
              <calsoft-text-editor [data]="data" [text]="section?.customField6"
                [ngStyle.lt-md]="{'font-size': item.customField28}">
              </calsoft-text-editor>
            </span>
            <span style="text-align: center;">
              <calsoft-text-editor [data]="data" [text]="section?.customField7"
                [ngStyle.lt-md]="{'font-size':item.customField31}">
              </calsoft-text-editor>
            </span>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  
  
  
  

  <div *ngIf="item.customField10=='Type 3'" class="container" style="    padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 80rem;" fxHide fxShow.gt-sm>
    <!-- Left Section -->
    <div class="left-section" style="padding-right: 3.5rem;">
      <div fxLayout="row" fxLayoutAlign="center" class="centered-content">
        <div fxLayout="column" class="sticky-content">
          <span class="section-title">
            <calsoft-text-editor [data]="data" [text]="item?.title" style="line-height: normal;"
              [ngStyle.gt-md]="{'font-size': item.customField33 }" [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
            </calsoft-text-editor>
          </span>
          <span class="description">
            <calsoft-text-editor [data]="data" [text]="item?.customField20"
              [ngStyle.gt-md]="{'font-size': item.customField21 }" [ngStyle.lt-md]="{ 'font-size': item.customField22 }">
            </calsoft-text-editor>
  
          </span>
        </div>
      </div>
    </div>
  
    <!-- Right Section -->
    <div class="right-section" fxFlex="60" contentAnim [aniName]="item.customField36">
      <div *ngFor="let section of images;let i=index" class="item">
        <div class="section" fxLayout.gt-md="column" fxFlex.gt-md="100" fxLayout.lt-md="column" >
          <div class="content-container" style="    padding-left: 1.75rem;
                            padding-right: 1.75rem;
                            background: #F1F1F1;
                            border-radius: 30px;
                            padding-top: 2.25rem;
                            padding-bottom: 1.75rem;">
            <div class="text-content">
              <span style="font-size: 3rem;letter-spacing: -2px;font-weight: 700;        line-height: 55px !important;">
                0{{i+1}}
              </span>
              <span class="section-title-two">
                <calsoft-text-editor [data]="data" [text]="section.customField6"
                  [ngStyle.gt-md]="{'font-size': item.customField27 }">
                </calsoft-text-editor></span>
              <span class="description">
                <calsoft-text-editor [data]="data" [text]="section.customField7"
                  [ngStyle.gt-md]="{'font-size': item.customField29 }">
                </calsoft-text-editor></span>
            </div>
          </div>
        </div>
      </div>
      
  
    </div>
  </div>
  <div  *ngIf="item.customField10=='Type 3'" fxLayout="column" fxHide fxShow.lt-sm style="    padding-left: 1.5rem;
                      padding-right: 1.5rem;
                      padding-top: 3.5rem;
                  ">
    <div fxLayout="column">
      <div fxLayout="row" fxFlex="80" style="width: 100%;">
        <div fxLayout="column" style="padding: 0 1%;">
  
          <span style="text-align: center;margin-bottom: 15px">
            <calsoft-text-editor [data]="data" [text]="item?.title" [ngStyle.lt-md]="{'font-size': item.customField34 }">
            </calsoft-text-editor></span>
          <span style="text-align: center;margin-bottom: 20px">
            <calsoft-text-editor [data]="data" [text]="item?.customField20"
              [ngStyle.lt-md]="{'font-size': item.customField22 }">
            </calsoft-text-editor>
          </span>
        </div>
      </div>
    </div>
  
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md="start" style="row-gap: 1.25rem;" contentAnim [aniName]="item.customField36">
      <div *ngFor="let section of images;let i=index" class="content-container" style="    padding-left: 1.75rem;
                            padding-right: 1.75rem;
                            background: #F1F1F1;
                            border-radius: 30px;
                            padding-top: 2.25rem;
                            padding-bottom: 1.75rem;">
        <div class="text-content">
          <span style="font-size: 3rem;letter-spacing: -2px;font-weight: 700;        line-height: 55px !important;">
            0{{i+1}}
          </span>
          <span class="section-title-two">
            <calsoft-text-editor [data]="data" [text]="section.customField6"
              [ngStyle.gt-md]="{'font-size': item.customField27 }">
            </calsoft-text-editor></span>
          <span class="description">
            <calsoft-text-editor [data]="data" [text]="section.customField7"
              [ngStyle.gt-md]="{'font-size': item.customField29 }">
            </calsoft-text-editor></span>
        </div>
      </div>
      
    </div>
  </div>
  