<div class="video-modal">
  <div fxLayout="row" fxLayout.xs="row" style="height: 100vh;" fxLayoutAlign="space-between stretch">
    <div fxFlex="33" style="background-color: #ededed;" fxFlex.xs="100" >
      <div style="margin-top: 10px;">
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <img  [alt]="'images'" [src]="serverPath1 + data.data.customField9"
          onerror="this.src='assets/images/products/noImage/no-image.png';" style="width: 100%; height: auto;" />
      </div>
      
      <div  class="partnerDatas">
        <div fxLayout="row">
          <h6>Partner</h6>
        </div>
        <div fxLayout="row">
          <h3><b>{{data.data.title}}</b></h3>
        </div>
        <div fxLayout="row">
          <h6>Category</h6>
        </div>
        <div fxLayout="row">
          <p>{{data.data.customField3}}</p>
        </div>
        <div fxLayout="row">
          <h6>Status</h6>
        </div>
        <div fxLayout="row" *ngIf="data.data.active == 1">
          <p>Active</p>
        </div>
        <div fxLayout="row" *ngIf="data.data.active == 0">
          <p>InActive</p>
        </div>
      </div>
    </div>

    <div fxFlex="2">

    </div>

    <div fxFlex="60" fxFlex.xs="100">
      <div>
        <div style="margin-top: 50px;">
        </div>

        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let slide of Imagedata">
            <img [src]="serverPath + slide.value" style="width: 550px;height: 300px;"
              [ngStyle.lt-md]="{ 'width': '100%'}">
          </ng-template>
        </owl-carousel-o>
        <p [innerHTML]="data.data.description" [ngStyle.lt-md]="{ 'padding': '10px'}"></p>
      </div>
    </div>
  </div>

  <button type="button" class="close" (click)="closeModal()" autofocus=false><span>
      <mat-icon>close</mat-icon>
    </span></button>
</div>
