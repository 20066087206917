<div *ngIf="display">
    <header class="header theme-container" fxHide fxShow.gt-md>
        <div class="header-top bg-blue primary">
            <div class="container">
                <div class="header-left">
                    <mat-icon>location_on</mat-icon> {{data.storeHoursOfOperation}} &nbsp;&nbsp;&nbsp;
                    <div class="header-left" style="cursor: pointer;" (click)="callPhone()">
                        <mat-icon class="icon-css">call</mat-icon>Call: {{data.storePhoneNumber}}
                    </div>
                </div>
                <div class="header-right">
                    <div *ngIf="showFooterIcon==1">
                        <div>
                            <span *ngFor="let item of footerIconData; let i = index">
                                <button (click)="navigate(item)" mat-icon-button>
                                    <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                                </button>
                            </span>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <mat-toolbar-row fxLayoutAlign="start center" style="height: auto;">

            <div fxFlex="5">

            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxFlex="15">
                <section class="padding-10">

                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>
            </div>

            <div fxLayout="row" fxLayoutGap="10px" fxFlex="data.disableSearchInHeader==0 ? 80 : 65">

                <calsoft-Menu [color]="false" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                    [categoryArray]="item"></calsoft-Menu>

            </div>

            <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
                <div *ngIf="data.disableSearchInHeader==0">
                    <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                        <mat-icon style=" cursor: pointer;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                            (click)="showSearchBar()">search</mat-icon>
                    </div>


                    <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                        fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
                        <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                            [searchbarborderRadius]="'15px'"></calsoft-search>
                    </div>
                </div>

            </div>


            <div fxLayoutAlign="end center" fxFlex
                *ngIf="url !== '/cart' && url !== '/checkout' && url !=='/checkout/type' ">

                <div *ngIf="data.disableWishlistHeader==0">
                    <button (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
                        <mat-icon>favorite_border</mat-icon>

                    </button>
                </div>

                <!-- <calsoft-cart-icon [data]="data"  fxFlex></calsoft-cart-icon> -->

            </div>

            <div fxLayoutAlign="end center" fxFlex="10">
                <div *ngIf="data.disableLoginHeader==0">
                    <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
                        <div style="color: black" fxLayout="column">
                            <small>Hello, Sign in</small>
                            <span>Account & Lists</span>
                        </div>
                    </button>
                </div>
                <div *ngIf="data.disableLoginHeader==0">
                <calsoft-HeaderUserProfileDropdown [data]="data"  class="headerUser"
                    *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>
                </div>
            </div>
        </mat-toolbar-row>

        <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header" fxLayout="row"
            fxLayoutAlign="space-between center" style="height: auto;z-index: 1000;">

            <mat-toolbar-row class="theme-container" fxLayoutAlign="space-between center" style="height: auto;">
                <div fxFlex="5">

                </div>

                <div fxLayout="row" fxLayoutGap="10px" fxFlex="15">
                    <section>

                        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                    </section>
                </div>

                <div fxLayout="row" fxLayoutGap="10px" fxFlex="data.disableSearchInHeader==0 ? 80 : 65">

                    <calsoft-Menu [color]="false" [data]="data" [type]="'one'" style="color: #000000; z-index: 1000;"
                        class="menu" [categoryArray]="item"></calsoft-Menu>

                </div>

                <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
                    <div *ngIf="data.disableSearchInHeader==0">
                        <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                            <mat-icon style=" cursor: pointer;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                                (click)="showSearchBar()">search</mat-icon>
                        </div>


                        <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                            fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
                            <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                                [searchbarborderRadius]="'15px'"></calsoft-search>
                        </div>
                    </div>

                </div>

                <div *ngIf="data.disableLoginHeader==0">
                <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
                    <div style="color: black" fxLayout="column">
                        <small>Hello, Sign in</small>
                        <span>Account & Lists</span>
                    </div>
                </button>
                </div>
                <div *ngIf="data.disableLoginHeader==0">
                <calsoft-HeaderUserProfileDropdown  [data]="data" [type]="scroll" class="headerUser"
                    *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>
                </div>

                <div fxFlex="3">

                </div>
            </mat-toolbar-row>
        </mat-toolbar>

    </header>
</div>


<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>
    <div *ngIf="mobileHeaderType==8">    
        <calsoft-mobile-header9 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header9>
    </div>
</div>