<div class="container">
  <div fxLayout="row" fxLayoutAlign.lt-md="center center" fxFlex.lt-md="100" fxLayoutAlign="end end">
    <div *ngIf="type==1" fxLayout="column" fxLayoutAlign="end start" fxFlex="80%" fxFlex.xs="100%"  class="premium-container">
      <mat-card class="premium-card" fxFlex.lt-md="100">
        <!-- <mat-card-title class="title"></mat-card-title> -->
        <mat-card-content>
          <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="label">Subscription Cost:</span>
            <span class="value">{{subscriptionCost | currencyConvert}}</span>
          </div>
          <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="label">One time Set-up cost :</span>
            <span class="value">{{setupCost | currencyConvert}}</span>
          </div>
          <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="label">One time Signing amount:</span>
            <span class="value">{{ signupCost | currencyConvert}}</span>
          </div>
          <div *ngIf="tax" class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="label">IGST / SGST (18%) :</span>
            <span class="value">{{taxAmount | currencyConvert}}</span>
          </div>
          <div class="cost-item total" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="label">Total:</span>
            <span class="value">{{ calculateTotal() | currencyConvert}}</span>
          </div>
          <div *ngIf="discountEnabled && discountAmt > 0" class="discount-info" fxLayout="row"
            fxLayoutAlign="space-between center">
            <span class="label" style="color: green; font-weight: bold;">Discount:</span>
            <span class="value" style="color: green; font-size: larger; font-weight: bold;">
              - {{ discountAmt | currencyConvert }}
            </span>
          </div>

          <div class="costfontsize">
            Cost Per Room Per Day
          </div>

          <div class="costfontsize">
            <span> ( {{subscriptionCost}} + {{signupCost}} = {{totaldailyCost}} / 365 / 10 = {{dailyCost}} )</span>
          </div>

          <div class="promo-section" fxLayout="column" fxLayoutAlign="center stretch">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="promo-row">
              <span class="label">Promo Code:</span>
              <span class="promo-code">{{ promoCode }}</span>
              <button mat-raised-button color="primary" (click)="applyPromoCode()"> {{ isPromoApplied ? 'Remove' :
                'Apply' }}</button>
            </div>
            <button *ngIf="!isPromoApplied"  mat-flat-button color="accent" class="coupon-button" (click)="openCouponDialog()">
              Show Available Coupons
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>



  </div>

  <div fxLayout="row" fxLayoutAlign="end end">
    <div *ngIf="type==2" fxLayout="column" fxLayoutAlign="space-evenly start" class="cost-container">
      <div fxLayout="column" fxLayoutAlign="start">
        <div class="cost-item" fxLayout="row">
          <h3>Total Cost : &nbsp;</h3>
          <h3>{{totalGrandTotalWithoutTax || 0.0 | currencyConvert}}</h3>
        </div>
        <div class="cost-item" fxLayout="row">
          <h3>SGST / IGST : &nbsp;</h3>
          <h3>{{totalTax || 0.0 | currencyConvert}} </h3>
        </div>
      </div>
      <div fxLayoutAlign="end end">
        <h3> Grand Total : &nbsp;</h3>
        <h3>{{totalGrandTotal || 0.0 | currencyConvert}} </h3>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="type==3" fxLayout="column" fxLayoutAlign="space-evenly start" class="cost-container2">
      <div fxLayoutAlign="center center" style="width:100% ;">
        <h2>TOTAL ESTIMATE COST</h2>
      </div>
      <div fxLayout="column" fxLayoutAlign="start" style="width:100% ;">
        <div class="cost-item" fxLayout="row">
          <h3 fxFlex="50"> Subscription Cost :</h3>
          <h3 fxFlex="50">{{subscriptionCost | currencyConvert}}</h3>
        </div>
        <div class="cost-item" fxLayout="row">
          <h3 fxFlex="50"> Additional Amenities & Supplies :</h3>
          <h3 fxFlex="50">{{totalGrandTotalWithoutTax || 0.0 | currencyConvert}}</h3>
        </div>
        <div class="cost-item" fxLayout="row">
          <h3 fxFlex="50"> SGST / IGST :</h3>
          <h3 fxFlex="50">{{totalTax+taxAmount || 0.0 | currencyConvert}}</h3>
        </div>
      </div>

      <div *ngIf="formValid" fxLayoutAlign="center center" style="width:100% ;">
        <app-razor-pay [contact]="contact" [partialAmountEnabled]="0" (paymentStatus)="paymentResponceRazorPAy($event)"
          [finalAmount]="subscriptionCost+totalGrandTotalWithoutTax+totalTax+taxAmount"></app-razor-pay>
      </div>
    </div>
  </div>
</div>