import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { AboutService } from 'src/app/services/about.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';

@Component({
  selector: 'app-policy-popup',
  templateUrl: './policy-popup.component.html',
  styleUrls: ['./policy-popup.component.scss']
})
export class PolicyPopupComponent implements OnInit {

  title: any;

  @Input() pagetitle: any;


  page: number;
  size: any;
  data: any;


  constructor(
    private aboutService: AboutService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
  ) {
    this.data = data

  }

  aboutData:any;
  

  ngOnInit() {

    console.log("page title" , this.data);

    this.title = this.data.pagetitle;
    
    
    if(this.data.pagetitle!=null){
      this.getAboutData(this.data.pagetitle);
    }




  }


  close(){
    this.dialog.closeAll();
  }


  getAboutData(pagetitle) {

    this.aboutService.getPageByIdNew(pagetitle,this.page,this.size).subscribe(
      (res) => {
        this.aboutData = res['content'];

      }
    )
  }

}
