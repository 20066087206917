import { Component, Inject, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { ContactService } from 'src/app/services/contact.service';
import { SupplierRegistrationService } from 'src/app/services/supplier-registration.service';

@Component({
  selector: 'calsoft-dynamic-form-popup',
  templateUrl: './dynamic-form-popup.component.html',
  styleUrls: ['./dynamic-form-popup.component.scss']
})
export class DynamicFormPopupComponent {


  data: any;

  text: string;

  regConfig: any[] = [];

  @Input() input: any;
  @Input() item: any;

  popupForm: any;
  popupText: any;

  customField31: any;

  
  disabledButton:any=false;

  customField28: any = 'Form Submitted SuccessFully';
  customField29: any = 'Form Submitted Failed';
  customField10: any = '/home';
  popupFormSubmittedType: any;


  constructor(private dialogRef: MatDialogRef<DynamicFormPopupComponent>,
    public snackBar: MatSnackBar,
    private contactService: ContactService,
    private configService: ConfigServiceService,
    private supplierRegistrationService: SupplierRegistrationService,
    private router: Router,
    // @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
  ) {

    // this.data = data;

  }

  init: boolean = false;

  ngOnInit() {

    this.getConfigData();

    this.createDynamicForm(this.popupForm);

    this.text = 'Submit';

  }


  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
  
          this.popupForm=data['popupForm'];
  
          this.popupText=data['popupText'];

          this.popupFormSubmittedType=data['popupFormSubmittedType'];
          
  
        }, error => {
  
        }
      );
  }


  createDynamicForm(formGroupId) {
    this.contactService.getDynmaicForm(formGroupId).subscribe((data) => {

      let value: any = data
      this.regConfig = value;
      this.init = true;
    });
  }


  close() {
    this.dialogRef.close({ event: 'close' })
  }


  submit(value: any) {

    let filteredList = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('button'))
      .map(item => ({ label: item.label }));




    filteredList.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        delete value[label.label];
      }
    });

    let filteredListFile = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('file'))
      .map(item => ({ label: item.name }));

    filteredListFile.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        const newKey = label.label + '_file';
        value[newKey] = value[label.label];
        delete value[label.label];
      }
    });

    let object = {};
    let keysToCheck = ["phoneNumber", "email", "name"];
    let keysList = keysToCheck.filter(key => value.hasOwnProperty(key));
    keysList.forEach(key => {
      object[key] = value[key];
    });

    object['formGroupId'] = this.popupForm
    object['value'] = JSON.stringify(value);



    if (this.popupFormSubmittedType == 1) {
      this.saveForm(object);
    } else if (this.popupFormSubmittedType == 2) {
      this.Calculation(object);
    } else if (this.popupFormSubmittedType == 3) {
      // this.Register(object);
    }else if (this.popupFormSubmittedType == 4) {
      this.onSubmitRoohi(object);
    } else {
      this.SubmitVendor(object);
    }

  }


  saveForm(object) {
    if (this.text == 'Submit') {
      this.contactService.saveForm(object).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.text = "Submit";
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.close();
            this.router.navigate([''])
          }
          else {
            this.text = "Submit";
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { this.text = "Submit" });
    }
    else {

    }
  }


  Calculation(object) {

    const parsedValue = JSON.parse(object.value);

    const calculatedResult = parsedValue.calculatedResult;

    console.log('Calculated Result:', calculatedResult);
    console.log('Parsed Object:', parsedValue);

    this.disabledButton=true;

  }

  SubmitVendor(object) {

    if (this.text == 'Submit') {

      if (object.address == null) {
        object.address = 0;
      }

      if (object.gstNo == null) {
        object.gstNo = 0;
      }

      if (object.phoneNo == null) {
        object.phoneNo = 0;
      }

      if (object.pincode == null) {
        object.pincode = 0;
      }

      if (object.state == null) {
        object.state = 0;
      }

      if (object.city == null) {
        object.city = 0;
      }

      if (object.accountNo == null) {
        object.accountNo = 0;
      }

      if (object.confirmAccount == null) {
        object.confirmAccount = 0;
      }

      if (object.ifscCode == null) {
        object.ifscCode = 0;
      }

      if (object.storeName == null) {
        object.storeName = 0;
      }

      if (object.country == null) {
        object.country = 'India';
      }

      if (object.email == null) {
        object.email = 0;
      }

      if (object.password == null) {
        object.password = 0;
      }

       this.text = "Processing"

      if (object.operationType = "New") {
        this.supplierRegistrationService.submitSupplier(object).subscribe(
          (response) => {
            if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
              this.text = "Submit";

              this.snackBar.open('Form Submitted SuccessFully!', 'X', {
                duration: 5000,
                verticalPosition: 'bottom',
                panelClass: ['success'],
              });
              this.close();
              this.router.navigate([''])
            }
            else {
              this.text = "Submit";
              this.snackBar.open('Form Submitted Failed!', 'X', {
                duration: 5000,
                verticalPosition: 'bottom',
                panelClass: ['error'],
              });
            }
          },
          (err) => { this.text = "Submit" });
      }
      else {

      }
    }


  }

  onSubmitRoohi(object) {

    console.log(object);
    
    localStorage.setItem('formValues', JSON.stringify(object));

      this.contactService.saveFormNew(object).subscribe(
        (response) => {

          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.text = "Submit";
            this.snackBar.open('Form Submitted SuccessFully!', 'X', {
              duration: 5000,
              verticalPosition: 'bottom',
              panelClass: ['success'],
            });
            this.close();
            this.router.navigate([''])
          }
          else {
            this.text = "Submit";
            this.snackBar.open('Form Submitted Failed!', 'X', {
              duration: 5000,
              verticalPosition: 'bottom',
              panelClass: ['error'],
            });
          }
        },
        (err) => {
          this.disabledButton = true;
          this.text = "Submit"
        });

  }

}
