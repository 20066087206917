<div class="container">
    <div fxLayout="row" fxLayoutAlign="end end">

        <div *ngIf="type==1" fxLayout="column" fxLayoutAlign="space-evenly start" class="cost-container">
            <div class="cost-item" fxLayout="row">
                <h3>Subscription Cost : &nbsp;</h3>
                <h3>{{subscriptionCost | currencyConvert}}</h3>
            </div>
            <span *ngIf="tax">
                <div class="cost-item" fxLayout="row">
                    <h3>GST : &nbsp;</h3>
                    <h3>{{taxAmount | currencyConvert}}</h3>
                </div>
            </span>
            <div class="cost-item" fxLayout="row">
                <h3>Total : &nbsp;</h3>
                <h3>{{totalCost | currencyConvert}}</h3>
            </div>

        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end">
        <div *ngIf="type==2" fxLayout="row" fxLayoutAlign="end start" fxLayoutAlign.lt-md="center center"
            fxFlex.lt-md="100" fxFlex="80%" class="cost-container">
            <mat-card class="premium-card" fxFlex="40" fxFlex.lt-lg="60" fxFlex.lt-md="100">
                <!-- <mat-card-title class="title"></mat-card-title> -->
                <mat-card-content>

                    <div fxLayout="column" fxLayoutAlign="start stretch" class="cost-details">
                        <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
                            <span class="label">Total Cost:</span>
                            <span class="value">{{totalGrandTotalWithoutTax || 0.0 | currencyConvert}}</span>
                        </div>
                        <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
                            <span class="label">SGST / IGST (18%):</span>
                            <span class="value">{{totalTax || 0.0 | currencyConvert}}</span>
                        </div>
                        <div class="cost-item total" fxLayout="column" fxLayoutAlign="start stretch">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span class="label">Grand Total:</span>
                                <span class="value">{{ totalGrandTotal || 0.0 | currencyConvert }}</span>
                            </div>


                        </div>

                        <div *ngIf="discountEnabled && discountAmt > 0" class="discount-info" fxLayout="row"
                            fxLayoutAlign="space-between center">
                            <span class="label" style="color: green; font-weight: bold;">Discount:</span>
                            <span class="value" style="color: green; font-size: larger; font-weight: bold;">
                                - {{ discountAmt | currencyConvert }}
                            </span>
                        </div>

                    </div>


                    <div class="promo-section" fxLayout="column" fxLayoutAlign="center stretch">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="promo-row">
                            <span class="label">Promo Code:</span>
                            <span class="promo-code">{{promoCode}}</span>
                            <button mat-raised-button color="primary" (click)="applyPromoCode()"> {{ isPromoApplied ?
                                'Remove' : 'Apply' }}</button>
                        </div>
                        <button *ngIf="!isPromoApplied"  mat-flat-button color="accent" class="coupon-button" (click)="openCouponDialog()">
                            Show Available Coupons
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <div *ngIf="type==3" fxLayout="row" fxLayoutAlign="center center" class="cost-container-two"
            style="width:100% ;">
            <div fxLayout="column" fxLayoutAlign="space-evenly start" fxFlex.lt-md="100" class="cost-container2"
                style="width:100% ;">
                <div fxLayoutAlign="center center" style="width:100% ;">
                    <h2 class="title">TOTAL ESTIMATE COST</h2>
                </div>
                <div fxLayout="column" fxLayoutAlign="start stretch" style="width:100% ;">
                    <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
                        <h3 fxFlex="50" class="label">Subscription Cost+ Set-up Cost + Signing Amount :</h3>
                        <h3 fxFlex="50" class="value">{{ finalSubscriptions | currencyConvert}}</h3>
                    </div>
                    <span *ngIf="totalGrandTotal > 0">
                        <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
                            <h3 fxFlex="50" class="label">Additional Amenities & Supplies:</h3>
                            <h3 fxFlex="50" class="value">{{totalGrandTotal | currencyConvert}}</h3>
                        </div>
                    </span>
                    <span *ngIf="(totalTax + taxAmount) > 0">
                        <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
                            <h3 fxFlex="50" class="label">SGST / IGST (18%):</h3>
                            <h3 fxFlex="50" class="value">{{totalTax + taxAmount | currencyConvert}}</h3>
                        </div>
                    </span>


                    <span *ngIf="subscriptionDiscount > 0 || subscriptionAdditionalDiscount > 0">
                        <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
                            <h3 fxFlex="50" style="color: green; font-weight: bold;" class="label">Discount:</h3>
                            <h3 fxFlex="50" class="value"> - {{subscriptionDiscount + subscriptionAdditionalDiscount | currencyConvert}}</h3>
                        </div>
                    </span>

                    <div class="cost-item" fxLayout="row" fxLayoutAlign="space-between center">
                        <h3 fxFlex="50" class="label">Grand Total:</h3>
                        <h3 fxFlex="50" class="value">{{(totalTax + taxAmount + finalSubscriptions +
                            totalGrandTotal) - ( subscriptionDiscount + subscriptionAdditionalDiscount) || 0.0 | currencyConvert}}</h3>
                    </div>
                </div>


                <div *ngIf="enablePayment" fxLayoutAlign="center center" style="width:100% ;">
                    <app-razor-pay [contact]="contact" [partialAmountEnabled]="0"
                        (paymentStatus)="paymentResponceRazorPAy($event)"
                        [finalAmount]="(totalTax + taxAmount + finalSubscriptions +
                        totalGrandTotal) - ( subscriptionDiscount + subscriptionAdditionalDiscount)">
                    </app-razor-pay>
                </div>

                <div *ngIf="!enablePayment" fxLayoutAlign="center center" style="width:100% ;">
                    <button [disabled]="!enablePayment" style="color: white; width: 150px; height: 45px;"
                        class="primary" mat-raised-button>
                        Pay Now
                    </button>
                </div>

            </div>
        </div>

    </div>
</div>