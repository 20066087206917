<div fxLayout="row" fxLayoutAlign="space-between center" class="card" >
    <div  class="leftside" >
        <span class="label-text">{{ field.label }}</span>
    
    </div>

    <div  class="right">
        <button (click)="downloadPdf(field.customField7)" mat-icon-button class="download-btn" 
                matTooltip="Download Document" aria-label="Download button"
                >
            <div class="download-wrapper">
                <svg class="download-icon" viewBox="0 0 24 24">
                    <path class="arrow" d="M12 2v12m0 0l-3-3m3 3l3-3m-6 7h6"/>
                    <path class="circle" d="M12 22a10 10 0 100-20 10 10 0 000 20z"/>
                </svg>
                <div class="progress-wave"></div>
            </div>
        </button>
    </div>
</div>


  