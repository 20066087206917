import { NgModule } from '@angular/core';
import { CalsoftScrollbarDirective } from './scrollbar.directive';

@NgModule({
    declarations: [
        CalsoftScrollbarDirective
    ],
    exports     : [
        CalsoftScrollbarDirective
    ]
})
export class CalsoftScrollbarModule
{
}
