import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-tab-layout2',
  templateUrl: './tab-layout2.component.html',
  styleUrls: ['./tab-layout2.component.scss']
})
export class TabLayout2Component {

  @Input() item: any;

  @Input() data: any;


  public youtubeUrl = environment.youtubeUrl;

  public serverPath1 = environment.commonImageApi + '/commonSlider/';

  public serverPath = environment.commonImageApi + '/home/';

  constructor(private commonService: CommonService,) { }

  selectedSubChildTitle: string = '';
 
  selectedIndexChange="defaultIndex($event)"

  ngOnInit() {

    this.defaultIndex = 0;
    this.toggleSecondDiv(this.defaultIndex);

    if (this.item.images[this.defaultIndex]?.subChild?.length) {
      this.selectedSubChildTitle = this.item.images[this.defaultIndex].subChild[0].customField5;
    }

  }

  defaultIndex: number;


  toggleSecondDiv(index: number) {
    this.defaultIndex = index;

    if (this.item.images[this.defaultIndex]?.subChild?.length) {
      this.selectedSubChildTitle = this.item.images[this.defaultIndex].subChild[0].customField5;
    } else {
      this.selectedSubChildTitle = '';
    }

  }

  selectedTech:any;

  selectTech(subChild: any) {
    this.selectedTech = subChild.customField5;
    this.selectedSubChildTitle = subChild.customField6; // Update title on click
  }

  goToCategories(id: any) {
    if (id > 0) {
      this.commonService.goToCategory(1, parseInt(id));
    }

  }


  link(url) {

    this.commonService.goToLink(url)
  }


}

