<div fxHide.lt-md>
    <div fxLayout="row " fxLayoutGap="10px" class="tabcontainer" >
        <div fxFlex="20" style="height: 100%;" >
            <nav class="sidebar">
                <ul>
                    <li *ngFor="let category of item.images let i=index" [class.active]="defaultIndex  === i"
                      (click)="toggleSecondDiv(i)">
                        {{ category.customField15 }}
                    </li>
                </ul>
            </nav>
        </div>
    
        <div fxFlex="80"  class="content" >

            <div fxLayout="column"  fxLayoutGap.xs="10px" fxLayoutGap="10px" class="tech-grid">


                <h2>{{ selectedSubChildTitle }}</h2>


                <div fxLayout="row" fxLayoutGap.xs="10px" fxLayoutGap="10px" >


                    <button *ngFor="let subChild of item.images[defaultIndex].subChild let j=index" class="tech-item"  fxLayout="row " 
                    [class.active]="selectedTech === subChild.customField5" (click)="selectTech(subChild)"
                    (mouseenter)="hoverTech = subChild.customField6" (mouseleave)="hoverTech = null">
                    <div fxFlex="50">
                        <img  [src]="hoverTech === subChild.customField6 ? serverPath+subChild.customField9 : serverPath+subChild.customField8" />
                    </div>
                    <div fxFlex="50" fxLayoutAlign="start center"  >
                        <span class="tech-name first">{{ subChild.customField6 }}</span>
                        <span class="tech-name second">{{ subChild.customField6 }}</span>
                    </div>    
                </button>

                </div>




            </div>

        </div>
    
    </div>
</div>

<div fxHide fxShow.lt-md>
    <div fxLayout="column" fxLayoutGap="10px" class="container">
       
        <mat-tab-group  [(selectedIndex)]="defaultIndex" (selectedIndexChange)="toggleSecondDiv($event)">
            <mat-tab *ngFor="let category of item.images let i=index" [class.active]="defaultIndex  === i" [label]="category.customField15">
            </mat-tab>
        </mat-tab-group>
    
   
        <div fxFlex="100"  class="content" >

            <div fxLayout="column"  fxLayoutGap.xs="10px" fxLayoutGap="10px" class="tech-grid">


                <h2>{{ selectedSubChildTitle }}</h2>


                <div fxLayout="column" fxLayoutGap.xs="10px" fxLayoutGap="10px" >


                    <button *ngFor="let subChild of item.images[defaultIndex].subChild let j=index" class="tech-item"  fxLayout="row " 
                    [class.active]="selectedTech === subChild.customField5" (click)="selectTech(subChild)"
                    (mouseenter)="hoverTech = subChild.customField6" (mouseleave)="hoverTech = null">
                    <div fxFlex="50">
                        <img  [src]="hoverTech === subChild.customField6 ? serverPath+subChild.customField9 : serverPath+subChild.customField8" />
                    </div>
                    <div fxFlex="50" fxLayoutAlign="start center"  >
                        <span class="tech-name first">{{ subChild.customField6 }}</span>
                        <span class="tech-name second">{{ subChild.customField6 }}</span>
                    </div>    
                </button>

                </div>




            </div>

        </div>
    </div>
    
</div>