<!-- <div class="pyrmaid-container">

    How does it work?

   <div class="image-container">
<img src="../../../../assets/images/bbimage.jpg" alt="alternateimage
">
   </div>
   <div class="description-container">
    <div class="slider slide-one">
        <div class="slider-heading">
            <h4>1. Join the program</h4>
        </div>
        <div class="slider-description">
<p>Become a Zoho affiliate. Get access to exclusive affiliate portal.</p>
        </div>
    </div>
    <div class="slider slide-two">
        <div class="slider-heading">
<h4>2. Promote the products</h4>
        </div>
        <div class="slider-description">
            <p>Share your unique affiliate links via blogs, social media posts, email newsletters, videos and other digital forums.</p>
        </div>
    </div>
    <div class="slider slide-three">
        <div class="slider-heading">
<h4>3. Earn commissions</h4>
        </div>
        <div class="slider-description">
            <p>Earn a share of the revenue for every qualified sale via your links for the next 12 months.</p>
        </div>
    </div>
   </div>
</div> -->





<div class="pyramid-container " fxHide fxShow.gt-sm>
    <span class="main-heading"> <calsoft-text-editor [data]="data" [text]="item?.title" [ngStyle.gt-md]="{'font-size': item.customField33 }"
        >
      </calsoft-text-editor></span>
  
    <div class="image-container">
      <img [attr.height]="item.customField28" 
      [attr.width]="item.customField29" [src]="serverPath1 + item.customField8" alt="Illustration" />
    </div>
  
    <div class="description-container" style="    margin-left: 90px">
      <div class="slider slide-one">
        <span class="slider-heading"><calsoft-text-editor [data]="data" [text]="item?.customField20" [ngStyle.gt-md]="{'font-size': item.customField26 }"
            >
          </calsoft-text-editor></span>
        <span class="slider-description"><calsoft-text-editor [data]="data" [text]="item?.customField21" [ngStyle.gt-md]="{'font-size': item.customField35 }"
            >
          </calsoft-text-editor></span>
      </div>
      <div class="slider slide-two">
        <span class="slider-heading"><calsoft-text-editor [data]="data" [text]="item?.customField22" [ngStyle.gt-md]="{'font-size': item.customField26 }"
            >
          </calsoft-text-editor></span>
        <span class="slider-description">
            <calsoft-text-editor [data]="data" [text]="item?.customField23" [ngStyle.gt-md]="{'font-size': item.customField35 }"
            >
          </calsoft-text-editor>
        </span>
      </div>
      <div class="slider slide-three">
        <span class="slider-heading"><calsoft-text-editor [data]="data" [text]="item?.customField24" [ngStyle.gt-md]="{'font-size': item.customField26 }"
            >
          </calsoft-text-editor></span>
        <span class="slider-description">
            <calsoft-text-editor [data]="data" [text]="item?.customField25" [ngStyle.gt-md]="{'font-size': item.customField35 }    ">
          </calsoft-text-editor>
        </span>
      </div>
    </div>
  </div>


<div class="pyramid-container-one" fxHide fxShow.lt-sm>
    <span class="main-heading"> <calsoft-text-editor [data]="data" [text]="item?.title"  [MobfontSize]="item.customField34">
    </calsoft-text-editor></span>
  
    <div class="image-container">
      <img [attr.height]="item.customField30" 
      [attr.width]="item.customField31" [src]="serverPath1 + item.customField8" alt="Illustration" />
    </div>
  
    <div class="description-container">
      <div class="slider slide-one">
        <span class="slider-heading"> <calsoft-text-editor [data]="data" [text]="item?.customField20"  [MobfontSize]="item.customField27">
        </calsoft-text-editor></span>
        <span class="slider-description"> <calsoft-text-editor [data]="data" [text]="item?.customField21"  [MobfontSize]="item.customField36">
        </calsoft-text-editor></span>
      </div>
      <div class="slider slide-two">
        <span class="slider-heading"> <calsoft-text-editor [data]="data" [text]="item?.customField22"  [MobfontSize]="item.customField27">
        </calsoft-text-editor></span>
        <span class="slider-description">
            <calsoft-text-editor [data]="data" [text]="item?.customField23"  [MobfontSize]="item.customField36">
            </calsoft-text-editor>
        </span>
      </div>
      <div class="slider slide-three">
        <span class="slider-heading"> <calsoft-text-editor [data]="data" [text]="item?.customField24"  [MobfontSize]="item.customField27">
        </calsoft-text-editor></span>
        <span class="slider-description">
            <calsoft-text-editor [data]="data" [text]="item?.customField25"  [MobfontSize]="item.customField36">
            </calsoft-text-editor>        </span>
      </div>
    </div>
  </div>

  