<div class="bg-image" [style.background-color]="item.customField8">
    <div fxLayoutAlign="center center">
        
        <div class="fast title" [style.background-color]="item.customField26" contentAnim [aniName]="img.customField36">
            <h4   [style.font-size]="item.customField23"  [ngStyle.lt-md]="{ 'font-size': item.customField24}" [innerHTML]="item.title"></h4>
        </div>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="20px" fxLayoutAlign="center center">

        <div fxFlex="100" fxFlex.gt-sm="10">

        </div>
        <div class="service" contentAnim [aniName]="img.customField36" *ngFor="let  img of item.images; let l = index" fxFlex="100" fxFlex.gt-sm="25" [style.height]="img.customField10">
            <h2   [style.font-size]="item.customField34"  [ngStyle.lt-md]="{ 'font-size': item.customField35}">{{img.customField4}}</h2>

            <img  width="500" height="500" [src]="serverPath1+img.customField6"
                class="attachment-medium_large size-medium_large" [alt]="img.altImage">
            

            <p   [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}" style="display: flex;" [innerHTML]="img.customField16"></p>

            <div>
                <br>
            </div>

            <a class="learn-more" (click)="openInNewWindow(img.customField7)" mat-raised-button color="primary">{{img.customField5}}</a>
        </div>
  
        <div fxFlex="100" fxFlex.gt-sm="10">

        </div>

    </div>
</div>