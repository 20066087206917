<div class="profile-card snipcss-MvXf3">
  <!-- <img decoding="async" [src]="serverPath+img.img" [alt]="img.customField1"> -->

  <img width="100%" [style.height]="item.height" [ngStyle.lt-md]="{
    'padding-top': i > 0 ? item.categoryId6 == 1 ? '10px' : 
    i == 0 ? '10px' : '0px' : '0px',
    'height': item.heightMob
}" [ngClass]="item.customField9==2 ? '' : 'collection-banner '" fxLayoutAlign="space-evenly stretch"
    (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [src]="serverPath+img.img"
    [alt]="img.altImage || 'images'" >

  <div class="hover-area alignment-center">
    <div class="profile-body">
      <h3 (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" class="profile-title">
        {{img.customField1}}
      </h3>
      <p class="profile-designation">
      </p>
      <p (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" class="profile-content"
        [innerHtml]="img.customField21">
      </p>
    </div>
  </div>
</div>