<div class="time-picker-component">
  <form [formGroup]="pinform">
      <mat-form-field appearance="outline" fxFlex="18" fxFlex.xs="25" fxLayoutGap="10px">
          <mat-select formControlName="ampm" (selectionChange)="onSelectionChange($event)">
              <mat-select-trigger>
                {{ getCountryCode(pinform?.get('ampm')?.value || '') }}

              </mat-select-trigger>
              <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search"
                      [formControl]="searchControl"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of filteredCountries" [value]="country.id">
                  <img [src]="country.flag">&#160; {{country.name}}</mat-option>
          </mat-select>   
      </mat-form-field>

      &#160;

      <div fxLayout="column" fxFlex fxLayoutGap="10px">
          <mat-form-field fxFlex appearance="outline">
            <mat-label>{{field.label}}
            </mat-label>
              <input numbersOnly
              (input)="trimSpaces('endTimeHour'); changeValue('endTimeHour', pinform.value.endTimeHour)"
                matInput
                type="tel"
                placeholder="Enter Number"
                id="end_time_hour"
                formControlName="endTimeHour"
                (click)="preventMatSelectOpen($event)"
                (keyup)="errmsg($event)"
              />
              
              <!-- Required field error -->
              <mat-error *ngIf="pinform.get('endTimeHour').hasError('required') && pinform.get('endTimeHour').touched">
                This field is required
              </mat-error>
            
              <!-- Invalid number length error -->
              <mat-error *ngIf="pinform.get('endTimeHour').hasError('customError')">
                Please Enter a Valid Number
              </mat-error>
            </mat-form-field>
       
      </div>
  </form>
</div>
