import { Component, ElementRef, Input, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { FormPopupComponent } from '../form-popup/form-popup.component';

@Component({
  selector: 'calsoft-vedio-banner-search',
  templateUrl: './vedio-banner-search.component.html',
  styleUrls: ['./vedio-banner-search.component.scss']
})
export class VedioBannerSearchComponent{
 
  private wrapper!: HTMLElement;
  private content!: HTMLElement;
  private isHovered = false;
  private attractionRadius = 200; // The distance at which attraction starts
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  public serverPath = environment.commonImageApi + '/commonSlider/';
  public serverPath1 = environment.commonImageApi + '/home/';
  @Input() item: any;
  
  @Input() img: any;

  @Input() data: any;

  public youtubeUrl = environment.youtubeUrl;
  
  image: any;

  constructor( private sanitizer: DomSanitizer,
    private commonService: CommonService,
    public dialog: MatDialog,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}
  

  ngOnInit() {
    
    this.image= this.serverPath+this.img[0].img;  
    
    console.log(this.image);
        
  }


  searchPage(){
    
  }

  @ViewChild('searchBox') searchBox!: ElementRef;
  @ViewChild('button') button!: ElementRef;

  isDragging = false;
  offsetX = 0;
  offsetY = 0;
  containerRect!: DOMRect;
  buttonRect!: DOMRect;

  ngAfterViewInit(): void {
    this.wrapper = this.el.nativeElement.querySelector('.magnetic-wrapper-container');
    this.content = this.el.nativeElement.querySelector('.magnetic-content-container');

    if (this.wrapper) {
      this.wrapper.addEventListener('mouseleave', () => {
        this.isHovered = false;
        this.resetEffect();
      });
    }
  }



  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (!this.wrapper || !this.content) return;

    const { clientX, clientY } = event;
    const { left, top, width, height } = this.wrapper.getBoundingClientRect();
    
    const centerX = left + width / 2;
    const centerY = top + height / 2;
    
    // Calculate distance between cursor and the center of the circle
    const deltaX = clientX - centerX;
    const deltaY = clientY - centerY;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    if (distance < this.attractionRadius) { // If within attraction range
      this.isHovered = true;

      const maxTranslate = 30; // Maximum movement of the element
      const translateX = (deltaX / distance) * maxTranslate;
      const translateY = (deltaY / distance) * maxTranslate;

      // Reduce the size slightly as it moves
      const scale = 1 - (distance / this.attractionRadius) * 0.2; // Shrinks by 20% at max attraction

      this.content.style.transform = `translate(${translateX}px, ${translateY}px) scale(${scale})`;
    } else {
      this.isHovered = false;
      this.resetEffect();
    }
  }
  private resetEffect(): void {
    this.content.style.transform = 'translate(0px, 0px) scale(1)'; // Reset to original state
  }


  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.content) {
      this.content.style.transform = 'translate(0px, 0px)';
    }
  }
  popupType = 3;
  dynamicFormPopup(formGroupId: any) {
    if (window.innerWidth > 768) {
      let dialogRef = this.dialog.open(FormPopupComponent, {
        width: '400px', 
        height: '100vh',
        position: { top: '0', right: '0' }, 
        panelClass: 'custom-dialog', 
        data: { formId: formGroupId, type: this.popupType },
        enterAnimationDuration: '400ms', 
        exitAnimationDuration: '100ms'   
      });
      dialogRef.afterOpened().subscribe(() => {
        this.cdr.detectChanges(); // Forces Angular to update the view
      });
  
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {});
    } else {
      let dialogRef = this.dialog.open(FormPopupComponent, {
        minWidth: '100vw',
        height: '100vh',
        position: { top: '0', right: '0' }, 
        panelClass: 'custom-dialog',
        data: { formId: formGroupId, type: this.popupType },
        enterAnimationDuration: '400ms',
        exitAnimationDuration: '100ms'
      });
  
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {});
    }
  }
  
  
    imageNavigation(id){
  
  
      if(id>0){
        this.commonService.goToCategory(1, parseInt(id));
      }
  
    }

    link(url) {

      this.commonService.goToLink(url)
     
    }
    
    scroll(id) {
    
      this.commonService.scrollDown(id)
     
    }

    
}

