
<div fxHide.lt-md class="container">

    <div fxFlex="33%" fxLayout="row" style="margin-right:10px ;" [fxLayoutGap]="item.gapBetweenImage" *ngFor="let images of item.images let d=index" class="ExamCourse_exam-preparation-card__3WIyX md:tw-flex">
        <div class="ExamCourse_exam-prepare-desc__1X_Pc">
            <p class="ExamCourse_title__UA5sm"
            [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">{{images.customField4}} </p>
            <h3 class=""
            [style.font-size]="item.customField29" [ngStyle.lt-md]="{ 'font-size': item.customField30 }">{{images.customField6}}</h3>
            <div *ngFor="let subLayout of images.subChild let d=index" class="ExamCourse_exam-button-list__3deFx">
                <a href="{{subLayout. customField11}}" class="Button_button__tSJmD Button_button--small__17kTZ    Button_button--no-transition__176Hp  Button_button--secondary__1uvV5 false"
                [style.font-size]="item.customField27" [ngStyle.lt-md]="{ 'font-size': item.customField28 }">{{subLayout.customField1}}</a>
            </div>
            <button type="button" class="Button_button__tSJmD Button_button--medium__2at61      Button_button--primary__1TO8y ExamCourse_primary-btn__TiGNo ExamCourse_explore-cta-ff__GqCje"><a href="{{images. customField11}}"  [style.font-size]="item.customField35" [ngStyle.lt-md]="{ 'font-size': item.customField36 }">{{images.customField10}}</a></button>
        </div>
        <div class="ExamCourse_exam-prepare-img__1F_tc">
            <div class="tw-hidden md:tw-block"><img    [src]="serverPath1+images.customField20" alt="exam-course"></div>
            <!-- <div class="tw-block md:tw-hidden"><img    [src]="serverPath1+images.customField20" alt="exam-course"></div> -->
        </div>
    </div>
</div>



<div fxHide fxShow.lt-md class="container">

    <div  fxLayout="column"  style="margin-bottom:10px ;" [fxLayoutGap]="item.gapBetweenImage" *ngFor="let images of item.images let d=index" class="ExamCourse_exam-preparation-card__3WIyX md:tw-flex">
        <div class="ExamCourse_exam-prepare-desc__1X_Pc">
            <p class="ExamCourse_title__UA5sm"
            [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">{{images.customField4}} </p>
            <h3 class=""
            [style.font-size]="item.customField29" [ngStyle.lt-md]="{ 'font-size': item.customField30 }">{{images.customField6}}</h3>
            <div *ngFor="let subLayout of images.subChild let d=index" class="ExamCourse_exam-button-list__3deFx">
                <a href="{{subLayout. customField11}}" class="Button_button__tSJmD Button_button--small__17kTZ    Button_button--no-transition__176Hp  Button_button--secondary__1uvV5 false"
                [style.font-size]="item.customField27" [ngStyle.lt-md]="{ 'font-size': item.customField28 }">{{subLayout.customField1}}</a>
            </div>
            <button type="button" class="Button_button__tSJmD Button_button--medium__2at61      Button_button--primary__1TO8y ExamCourse_primary-btn__TiGNo ExamCourse_explore-cta-ff__GqCje"><a href="{{images. customField11}}"           [style.font-size]="item.customField35" [ngStyle.lt-md]="{ 'font-size': item.customField36 }">{{images.customField10}}</a></button>
        </div>
        <div class="ExamCourse_exam-prepare-img__1F_tc">
            <div class="tw-hidden md:tw-block"><img    [src]="serverPath1+images.customField20" alt="exam-course"></div>
            <div class="tw-block md:tw-hidden"><img    [src]="serverPath1+images.customField20" alt="exam-course"></div>
        </div>
    </div>
</div>