import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { CouponComponent } from 'src/app/pages/coupon/coupon.component';
import { NewSubscriptionService } from 'src/app/services/new-subscription.service';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { ApplyCouponComponent } from '../apply-coupon/apply-coupon.component';
import { CheckoutService } from 'src/app/services/checkout.service';
@Component({
  selector: 'calsoft-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent {

  @Input() type: any = 1;

  
  @Input() SuccessMessage: any = 'Form Submitted SuccessFully';

  @Input() FailerMessage: any = 'Form Submitted Failed';

  @Input() redirectUrl: any = '/home';

  @Input() enablePayment: any;


  subscriptionCost: any = 0.0;
  subscriptionTotalCost: any = 0.0;
  subscriptionDiscount: any = 0.0;
  subscriptionAdditionalDiscount: any = 0.0;
  taxPercentage: any = 0.0;
  totalCost: any = 0.0;
  taxAmount: any = 0.0;
  totalGrandTotal: any = 0.0;
  currentMap: Map<string, any> = new Map();
  totalTax: number;
  totalGrandTotalWithoutTax: any = 0.0;
  form: FormGroup;

  formValid: boolean = false;
  subscriptionType: any;
  subscriptionImgName: any;

  signupCost: any;
  setupCost: any;


  referer: string;
  discountAmt: any;
  discountEnabled: boolean;
  couponResult: any;




  constructor(private subscriptionService: SubscriptionPriceService,
    private newSubscriptionService: NewSubscriptionService,
    private snackBar: MatSnackBar,
    private router: Router,
     private dialog: MatDialog,
    private clipboard: Clipboard,
        private checkOut: CheckoutService,
  ) { }

  
  applyCouponCode() {

  
    let coupon = {
      "customerId": 0,
      "shippingMethod": "store pickup",
      "couponCode": this.promoCode,
      "subTotal": this.totalGrandTotalWithoutTax,
      "grandTotal": this.totalGrandTotalWithoutTax,
      "totalQty": 0,
      "weight": 0,
    };
  
    let url = "applyCoupon";
    this.checkOut[url](coupon).subscribe(
      (response) => {
        if (response['status_code'] == Constant.RESPONSE_SUCCESS) {

          let amt = response['data']['discountAmt'];
          this.couponResult = response['data'];
          this.discountEnabled = true;
          this.discountAmt = response['data']['discountAmt']|| 0;
          console.log("the Discount Amount is "+ this.discountAmt);
          
          this.totalGrandTotal = this.totalGrandTotal - amt;

         this.subscriptionService.updateDiscountNew(this.discountAmt);
       }
       else {

          this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }
      },
      (err) => { }
    );
  }
  
  contact: {};
  
  ngOnInit() {

    this.referer=Constant.REFERER;

    localStorage.setItem('subscriptionDiscount', '0');

    localStorage.setItem('subscriptionAdditionalDiscount', '0');


    this.subscriptionService.subscriptionPrice$.subscribe(price => {
      this.subscriptionCost = Number(price);
      this.updateSubscriptionsCost();

    });

    this.subscriptionService.subscriptionTotalCost$.subscribe(Totalprice => {
      this.subscriptionTotalCost = Number(Totalprice);


    });

    this.subscriptionService.subscriptionDiscountCost$.subscribe(discountAmount => {
      this.subscriptionDiscount = Number(discountAmount);
    });

    this.subscriptionService.subscriptionAdditionalDiscountCost$.subscribe(AdditionaldDiscountAmount => {
      this.subscriptionAdditionalDiscount = Number(AdditionaldDiscountAmount);
    });



    this.subscriptionService.subscriptionTaxPercentage$.subscribe(taxPercentage => {
      this.taxPercentage = taxPercentage;
    });

    this.subscriptionService.subscriptionTotalCost$.subscribe(totalCost => {
      this.totalCost = totalCost;
    });

    this.subscriptionService.subscriptionTotalTaxAmount$.subscribe(taxAmount => {
      this.taxAmount = taxAmount;
      this.taxAmount = Number(taxAmount);
    });

    this.subscriptionService.GrandTotalAmount$.subscribe(totalGrandTotal => {
      this.totalGrandTotal = totalGrandTotal;
    });

    this.subscriptionService.subscriptionType$.subscribe(subscriptionType => {
      this.subscriptionType = subscriptionType;
    });

    this.subscriptionService.subscriptionImgName$.subscribe(subscriptionImgName => {
      this.subscriptionImgName = subscriptionImgName;
    });

    this.subscriptionService.subscriptionSetupCost$.subscribe(setupCost => {
      this.setupCost = setupCost;
      this.updateSubscriptionsCost();
    });
    
    this.subscriptionService.subscriptionSignUpCost$.subscribe(signupCost => {
      this.signupCost = signupCost;
      this.updateSubscriptionsCost();
    });


    this.subscriptionService.form$.subscribe(form => {

      if (form != null) {
        this.form = form;
        if (this.form.valid) {

          let object = {
            "name": this.form.value.name,
            "contact": this.form.value.phoneNumber,
            "email": this.form.value.email,
          }
          this.contact = object;
          this.formValid = true;
        }


      }

    });
    this.subscriptionService.getMap().subscribe(map => {
      this.currentMap = map;
      this.totalGrandTotal = 0.0;
      this.totalTax = 0.0;
      this.totalGrandTotalWithoutTax = 0.0;

      this.currentMap.forEach((value: any, key) => {

        value.forEach(element => {
  
          this.totalTax = this.totalTax + element.totalTaxAmount * element.quantity;

          if(element.inclusive==1){

            this.totalGrandTotalWithoutTax = this.totalGrandTotalWithoutTax + element.rowTotalWithoutTax * element.quantity;

            this.totalGrandTotalWithoutTax = this.totalGrandTotalWithoutTax 

          }else{
            this.totalGrandTotalWithoutTax = this.totalGrandTotalWithoutTax + element.rowTotalWithoutTax * element.quantity;
          }

          this.totalGrandTotal = this.totalGrandTotalWithoutTax + this.totalTax


          // if(element.inclusive==1){
          //   this.totalGrandTotal =  this.totalGrandTotalWithoutTax + this.totalTax
          // }else{
          //   this.totalGrandTotal = this.totalGrandTotal + element.rowTotal * element.quantity;
          // }
         

        });
      });

      this.totalTax = Number(this.totalTax)
      this.totalGrandTotalWithoutTax = Number(this.totalGrandTotalWithoutTax)
    });



  }

  finalSubscriptions:any = 0.0 ;
  updateSubscriptionsCost(){
    this.finalSubscriptions = (parseInt(this.subscriptionCost) + parseInt(this.signupCost) + parseInt(this.setupCost));
  }

  couponList = []; 
   openCouponDialog() {
      const dialogRef = this.dialog.open(ApplyCouponComponent, {
        width: '80%', 
        maxWidth: '600px',
        data: { couponList: this.couponList } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.setPromoCode(result);
        }
      });
    }
    promoCode: string = 'Select Coupon First'; 
    setPromoCode(coupon: string) {
      this.promoCode = coupon;
      this.isPromoApplied = false;
    }
    applyPromoCode() {
      if (this.isPromoApplied) {
    
        this.promoCode = "Select Coupon First"; 
        this.isPromoApplied = false; 
        this.discountAmt = 0;
        this.totalGrandTotal = this.totalGrandTotalWithoutTax+this.totalTax;
        return;
      }
    
      if (this.promoCode && this.promoCode !== "Select Coupon First") {
      
        this.isPromoApplied = true; 
        this.applyCouponCode(); 
        this.copyInputMessage(this.promoCode);
      } else {
        this.promoCode = "Select Coupon First"; 
      }
    }
    
  
    isPromoApplied: boolean = false; 
  
    copyInputMessage(code: string) {
      this.clipboard.copy(code);
      this.snackBar.open(`Coupon Code Copied`, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
    }


    Submittedform:any;

  paymentResponceRazorPAy(i) {
    let payment = 'razorPay';
    let paymentId = i.paypalPaymentStatus;
    let orderTranscationId = i.salesPaymentTransaction[0].txn_id;
    let mode = "razor_pay";
    let amentiesList1 = [];
    let amentiesList2 = [];
    let amentiesList3 = [];
    let index = 0;
    this.currentMap.forEach((value: any, key) => {


      if (index == 0) {

        amentiesList1 = value

      } else if (index == 1) {

        amentiesList2 = value
      } else {
        amentiesList3 = value
      }

      index++;
    });

   
    this.Submittedform = JSON.parse(localStorage.getItem('formValues'));

    this.Submittedform = JSON.parse(localStorage.getItem('formValues') || 'null');

    if (!this.Submittedform) {
        alert('Please submit the form first!');
    }


    let object = {
      paymentId: paymentId,
      paymentTranscId: orderTranscationId,
      mode: "razor_pay",
      subScriptionType: this.subscriptionType,
      additionalAmmenties: 1,
      subScriptionName: this.subscriptionImgName,
      total: (this.totalTax + this.taxAmount + this.finalSubscriptions + this.totalGrandTotal) - (this.subscriptionDiscount + this.subscriptionAdditionalDiscount),
      taxAmount: this.totalTax + this.totalTax,
      subscriptionCost: this.finalSubscriptions,
      subcribtionTax: this.taxAmount,
      ammentiesCost: this.totalGrandTotal,
      amentiesTax: this.totalTax,
      discountAmt: this.subscriptionDiscount + this.subscriptionAdditionalDiscount,
      amentiesList1: amentiesList1.length == 0 ? null : JSON.stringify(amentiesList1),
      amentiesList2: amentiesList2.length == 0 ? null : JSON.stringify(amentiesList2),
      amentiesList3: amentiesList3.length == 0 ? null : JSON.stringify(amentiesList3),
      form: this.Submittedform,

      paymentInformation: JSON.stringify({
        paymentId: paymentId,
        paymentTranscId: orderTranscationId,
        mode: "razor_pay"
    })
    }



    if (object != null) {
      this.finalSumbit(object);
    }


  }


  text: string;




  totalAmount(): number {
    return this.subscriptionCost + this.totalGrandTotalWithoutTax + this.totalTax;
  }

  finalSumbit(object) {

    if (object != null) {

      let formData = typeof object.form === 'string' ? JSON.parse(object.form) : object.form;

        if (formData.value && typeof formData.value === 'string') {
            formData.value = JSON.parse(formData.value);
        }


      let saveForm = {
        name: formData.name,
        contact: formData.phoneNumber,
        email: formData.email,
        accountNo: formData.accountNo,
        ifscCode: formData.bankIfscCode,
        gstNo: formData.gstNo,
        kycImg: formData.kycImg,
        panCard: formData.panCard,
        fassai: formData.fassai,
        shopEstablish: formData.shopEstablish,
        customField1: formData.customField1,
        customField2: formData.customField2,
        customField3: formData.customField3,
        customField4: formData.customField4,
        customField5: formData.customField5,
        customField6: formData.customField6,
        customField7: formData.customField7,
        customField8: formData.customField8,
        customField9: formData.customField9,
        customField10: object.paymentInformation,
        value: JSON.stringify(object),
      };

      Object.keys(formData.value).forEach((key) => {
        if (key.includes("_file")) {
          let newKey = key.replace("_file", ""); // Remove "_file" suffix if needed
          saveForm[newKey] = formData.value[key];
        }
      });


      if (saveForm != null) {
        this.newSubscriptionService.saveSubscriptionForm(saveForm).subscribe(
          (response) => {

            if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
              this.snackBar.open(this.SuccessMessage, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
              
              localStorage.removeItem("formValues");
              
              if (this.redirectUrl.includes('http://') || this.redirectUrl.includes('https://')) {
                window.location.href = this.redirectUrl;
              }else{
                this.router.navigate([this.redirectUrl]);
              }
            
             
            }
            else {
              this.snackBar.open(this.FailerMessage, '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
            }
          },
          (err) => { "object is null" });
      }
      else {
        (err) => {
          console.error('Error occurred:', err);
          this.snackBar.open('Failed to save data', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }

      }
    }
  }

  calculateTotal(): number {
    let totalGrandTotal = 0;
    // const subscriptionCostAmount = this.subscriptionCost;  
  
    // totalGrandTotal += subscriptionCostAmount;
    
    if (this.discountEnabled && this.discountAmt > 0) {
      return totalGrandTotal - this.discountAmt;
    }
  
    return totalGrandTotal=this.totalGrandTotal; 
  }


}

