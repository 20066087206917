import { Component, Input, OnInit } from '@angular/core';
import { Constant } from 'src/app/constants/constant';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { SwiperConfigInterface } from '../../swiper/swiper.module';
import { Router } from '@angular/router';

@Component({
  selector: 'calsoft-TopProducts',
  templateUrl: './TopProducts.component.html',
  styleUrls: ['./TopProducts.component.scss']
})
export class TopProductsComponent implements OnInit {
  @Input() categoryId: any;
  @Input() header: any;
  @Input() id: any;
  @Input() other: any;
  @Input() sortOrder: any;
  @Input() showCartIcon: any;
  @Input() showConfigurableDropdown: any;
  @Input() productImageHeight: any;
  @Input() height: any;
  @Input() width: any;
  @Input() imageType: any;
  @Input() data: any;
  @Input() slidesPerView: any;

  @Input() showCartIncremented: any;
  @Input() heightMobile: any;

  @Input() widthMobile: any;
  categoryProducts: any;
  count: any;
  isEmpty: any;
  pageSize: any;
  url: string;
  public page: any = 0;
  public config: SwiperConfigInterface = {};
  size: number = 5;
  mobileSize: number = 4;
  mobileSize2: number = 4;
  sortParam: any = 'entityId' + "," + Constant.Asc;
  private serverPath = environment.ImageAPI1;
  countryCode: string;
  @Input() item: any;
  categoryId5: any = 0;
  categoryId4: any = 0;

  customField25: any = 'white';
  customField26: any = 'View All';
  customField27: any;
  customField28: any;

  constructor(
    private commonService: CommonService,
    private productService: ProductService,
    private currencyConversionService: CurrencyConversionService,
    private userService: UserService,
    private cartServices: CartService,
    private router: Router
  ) { }

  ngOnInit() {

    if (this.item?.customField30 != undefined) {
      this.mobileSize2 = this.item.customField30;
    }

    if (this.slidesPerView != undefined) {
      this.slidesPerView = this.item.customField29;
    }
    this.url = this.router.url;

    if (window.innerWidth > 770 && window.innerWidth < 1024) {
      this.size = this.slidesPerView || 5
    }
    else if (window.innerWidth > 650 && window.innerWidth < 770) {
      this.size = 4
    } else if (window.innerWidth < 650) {
      this.size = this.mobileSize
    } else {
      this.size = this.slidesPerView || 5
    }


    let countryCode = this.currencyConversionService.getCountryCode();
    if (countryCode == null) {
      this.countryCode = "INR";
    }
    else {
      this.countryCode = countryCode;
    }

    this.productsByCategory();
    this.getCustomerCount();
    if (this.item != undefined) {
      if (this.categoryId4 != undefined) {
        this.categoryId4 = this.item.categoryId4;
      }

      if (this.categoryId5 != undefined) {
        this.categoryId5 = this.item.categoryId5;
      }

      if (this.customField26 != undefined) {
        this.customField26 = this.item.customField26;
      }

      if (this.item.customField27 != undefined) {
        document.documentElement.style.setProperty('--custom-width', this.item.customField27);
        this.customField27 = this.item.customField27;
      }


      if (this.item.customField28 != undefined) {
        document.documentElement.style.setProperty('--custom-width-mob', this.item.customField28);
        this.customField28 = this.item.customField28;
      }

    }

  }

  public productsByCategory() {


    if (this.other == 'category') {
      this.categoryId = this.commonService.getEncryptedId(parseInt(this.id))
      this.sortParam = this.sortOrder;
      this.productService.productsByCategoryIdx
        (decodeURIComponent(this.categoryId), this.page, this.size, this.sortParam).subscribe(
          res => {

            if (res != null) {
              this.categoryProducts = res['content'];
              this.count = res['totalElements'];
              this.isEmpty = res['empty'];
              this.pageSize = res['totalPages']
            } else {
              this.categoryProducts = [];
              this.count = 0;
              this.isEmpty = true;
              this.pageSize = 0
            }

          }

        )
    } else {

      let methodName = "getProductBySearch";
      let search = ''
      if (this.other == 'productScope') {
        methodName = "getProductByCustomProductId";
        search = this.id
      } else {

        let value = 1;
        search = "isInStock:" + '*' + value + '*'
          + "' AND featured:" + '*' + value + '*' + "' "
      }


      this.productService[methodName](search, this.page, this.size, this.sortOrder).subscribe(
        res => {
          this.categoryProducts = res['content'];
          this.count = res['totalElements'];
          this.isEmpty = res['empty'];
          this.pageSize = res['totalPages']
        })
    }
  }

  productShow(product) {


    this.commonService.productNavigation(product)


  }

  goToCategory() {
    this.commonService.goToCategory(1, parseInt(this.id));
  }
  values: any = "*values*";
  getCustomerCount() {

    let authourized = this.userService.getAuthenticatedUser();
    if (authourized) {


      this.cartServices.geWishListProduct();

      this.cartServices.getCustomerCartProductCount().subscribe(
        (response) => {


          this.values = response;

          let value = localStorage.getItem(Constant.CART_COUNT);
          if (value == null) {
            let encryptedValue = btoa(JSON.stringify(response));

            this.userService.storeProductCartCount(encryptedValue);
          }
        },
        (err) => { }
      );
    }
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: this.slidesPerView,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 3
        },
        740: {
          slidesPerView: 3
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        },
        1500: {
          slidesPerView: this.slidesPerView
        }
      }
    }
  }


  calculateFlex(size: number): string {
    const flexValue = (100 / size).toFixed(2); 
    return `${flexValue}%`; // Return percentage
  }

  
  calculateMobileFlex(mobileSize2: number): string {
    const flexValue = (100 / mobileSize2).toFixed(2); 
    return `${flexValue}%`; 
  }


}
