import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DeployService {

  url = environment.serverAPI7 + '/api';

  constructor(private http: HttpClient,
    private userService: UserService
  ) { }

  AddNewSite(site: any): Observable<Response[]> {
    let authourized = this.userService.getAuthenticatedUser();
    if (authourized) {
      return this.http.post<Response[]>(`${this.url}/format`, site);
    } else {
      return this.http.post<Response[]>(`${this.url}/ig/format`, site);
    }
  }

}
