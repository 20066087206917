import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { FieldConfig } from '../field.interface';
import { downloads } from 'src/app/pages/util/calsoft-validators';

@Component({
  selector: 'calsoft-pdf-download',
  templateUrl: './pdf-download.component.html',
  styleUrls: ['./pdf-download.component.scss']
})
export class PdfDownloadComponent {

  @Output() valueChange = new EventEmitter<any>();
  field: FieldConfig;
  group: UntypedFormGroup;
  text: string;
  public serverPath = environment.commonImageApi + 'gallery/';

  public serverPath1 = environment.commonImageApi + 'gallery1/';

  value: any;
  reInit: boolean = false;
  imageList: any[];
  @Input() customField13: any = 0;
  imagename: string;

  constructor(
    private snackBar: MatSnackBar,
    private productService: ProductService,) {
     }


     finalValue:any;

  ngOnInit(): void {
    
    
  }

  downloadPdf(value) {
      let smallImage = "/formPdf/" + value;
      this.productService.downloadPdf(smallImage).subscribe(
        data => {
          downloads(data,value);
        },
      )
  }

}

