<div *ngIf="loaded">

    <div *ngIf="layoutype==0">

        <div class="container pad">

            <p [innerHTML]="aboutData"> </p>

        </div>
    </div>

    <div *ngIf="layoutype==1">
        <div class="calsoft-vertical-navigation-content"
        calsoftScrollbar
        [calsoftScrollbarOptions]="{ suppressScrollX: true}"
        #navigationContent>
        <calsoft-layout [value]="3"  [pageNated]="true" [homePageData]="homePageBuilder" [type]="2"></calsoft-layout>
        </div>
    </div>

</div>