<mat-toolbar class="mobileheader">
    <mat-toolbar-row style="padding: 0px;" fxLayout="column" [style.margin]="mobileHeaderLogoGap"
        [style.height]="mobileHeaderHeight">

        <!-- logo Start -->
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100% ;">
            <section fxFlex="50" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="3px">
                <div fxFlex="20">
                    <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock"
                        class="responsive-toggle" mat-icon-button (click)="toggleSidebar()">
                        <i style="font-size: 18px" [style.color]="data.headerIconColour"
                            class="material-icons ">menu</i>
                    </button>
                    <ng-template #elseBlock>
                        <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button
                            (click)="back()">
                            <i class="material-icons ">keyboard_arrow_left</i>
                        </button>
                    </ng-template>
                </div>


                <div fxFlex="80" >
                    <div *ngIf="layout==0&&mobileLogoEnable==0" fxLayoutAlign="start center">
                        <calsoft-logo [sitename]="data.siteName" *ngIf="!displaySearchBar" [type]="'light'"></calsoft-logo>
                    </div>
    
                    <div *ngIf="layout==0&&mobileLogoEnable==1" fxLayoutAlign="start center">
                        <img *ngIf="!displaySearchBar" [src]="serverPath3+mobileLogoSrc" style="width: 100%;"
                            onerror="this.src='';" />
                    </div>
    
                </div>

                
            </section>

            <div *ngIf="url != '/cart' && layout==0" [fxFlex]="layout==0?3:0">

            </div>


            <section fxFlex="50" *ngIf="layout==0" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="2px">

                <div fxHide.lg fxHide.gt-lg fxHide.lt-sm fxFlex style="word-wrap: break-word;">
                    <p style="font-weight: 700;">{{data.headerNotificationContent}}</p>
                </div>

                <mat-icon class="icon-css" fxLayoutAlign="center center" (click)="gotoCall()">call</mat-icon>

                <div *ngIf="disableLoginHeader==0">
                    <mat-icon class="icon-css" fxLayoutAlign="center center" *ngIf="!userLoggedIn"
                        (click)="SignIn('login')">person</mat-icon>
                </div>

                <div style="padding: 18px;">
                    <button class="btncss" mat-button (click)="open_popup()">Get started</button>
                </div>

                <div *ngIf="data.disableLoginHeader==0">
                    <calsoft-HeaderUserProfileDropdown [data]="data" [style.color]="data.headerIconColour"
                        class="headerUser" *ngIf="userLoggedIn">
                    </calsoft-HeaderUserProfileDropdown>
                </div>

            </section>
        </div>

        <section fxLayout="row" fxLayoutAlign="center center" style="width: 100%; padding: 10px;">
            <div style="text-align: center; white-space: normal;font-weight: bold;">
                {{data.headerNotificationContent}}
            </div>
        </section>


    </mat-toolbar-row>



</mat-toolbar>




<mat-toolbar style="padding:0px ; height: 43px;" fxHide fxShow.lt-md class="calsoft-fixed-header primary" fxLayout="row"
    fxLayoutAlign="space-between center">
    <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

        <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock" class="responsive-toggle"
            mat-icon-button (click)="toggleSidebar()">
            <i style="font-size: 18px" class="material-icons ">menu</i>
        </button>
        <ng-template #elseBlock>
            <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button (click)="back()">
                <i class="material-icons ">keyboard_arrow_left</i>
            </button>
        </ng-template>
    </section>
    <section fxFlex *ngIf="data.disableSearchInHeader==0">
        <calsoft-search></calsoft-search>
    </section>
    <section>
        <calsoft-cart-icon *ngIf="disableCartIconInHeader==0" [data]="data" [style.color]="data.headerIconColour"></calsoft-cart-icon>
    </section>
</mat-toolbar>
<!-- Mobile View  end -->