<div class="video-container" *ngIf="item.customField10=='Type 1'">
  <div *ngIf="item.customField25=='0'">
    <video oncanplay="this.play()" onloadedmetadata="this.muted = true" [ngStyle.lt-md]="{'height': item.heightMob}"
      [style.height]="item.height" class="video-sc-desktop" autoplay muted loop>
      <source [src]="serverPath1 + item.customField6" type="video/mp4">
    </video>
  </div>
  <div *ngIf="item.customField25=='1'">
    <calsoft-ng-optimized-image class="video-sc-desktop" [height]="item.height" [heightMob]="item.heightMob"
      [altImage]="'images'" [image]="serverPath1+item.customField6" [i]="'0'">
    </calsoft-ng-optimized-image>
  </div>
  <div class="text-overlay">
    <div class="title">
      <calsoft-editor-text [data]="data" [text]="item.customField4" [align]="item.customField34"
        [style.font-size]="item.customField37" [ngStyle.lt-md]="{ 'font-size': item.customField38 }">
      </calsoft-editor-text>
    </div>
    <div class="descrption">
      <calsoft-editor-text [data]="data" [text]="item.customField5" [align]="item.customField34"
        [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
      </calsoft-editor-text>
    </div>
  </div>
  <div class="search-box-new">
    <calsoft-search3></calsoft-search3>
  </div>
</div>

<div class="video-container" *ngIf="item.customField10=='Type 2'">
  <div *ngIf="item.customField25=='0'">
    <video oncanplay="this.play()" onloadedmetadata="this.muted = true" [ngStyle.lt-md]="{'height': item.heightMob}"
      [style.height]="item.height" class="video-sc-desktop" autoplay muted loop>
      <source [src]="serverPath1 + item.customField6" type="video/mp4">
    </video>
  </div>
  <div *ngIf="item.customField25=='1'">
    <calsoft-ng-optimized-image class="video-sc-desktop" [height]="item.height" [heightMob]="item.heightMob"
      [altImage]="'images'" [image]="serverPath1+item.customField6" [i]="'0'">
    </calsoft-ng-optimized-image>
  </div>
  <div [ngClass]="item.customfield31 === 'Left' ? 'text-overlay-two' : 'text-overlay'">
    <div class="title">
      <calsoft-editor-text [data]="data" [text]="item.customField4" [align]="item.customField34"
        [ngStyle.gt-md]="{'letter-spacing': '1px', 'line-height': '1'}"
        [style.font-size]="item.customField37" [ngStyle.lt-md]="{ 'font-size': item.customField38 }">
      </calsoft-editor-text>
    </div>
    <div class="descrption" [ngStyle.gt-md]="{'max-width': '36rem'}">
      <calsoft-editor-text [data]="data" [text]="item.customField5" [align]="item.customField34"
        [ngStyle.gt-md]="{'line-height': '2rem'}"
        [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
      </calsoft-editor-text>
    </div>
    <div class="search-box-new2" style="margin-top: 1.25rem;" fxLayout="row " fxHide fxShow.lt-md>
      <button style="background-color: #2ed06e;border-style: none;"
        (click)="(item.customField30 == 0) ? imageNavigation(item.catId)
        : (item.customField30 == 1) ? dynamicFormPopup(item.value)
        : (item.customField30 == 2) ? link(item.navigatedUrl)
        : (item.customField30 == 3) ? scroll(item.customField35) : null">Let's discuss
      </button>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
  </div>
  <div class="float-right" fxHide.lt-md>
    <div class="circle-container">
      <div id="magnetic-wrapper" class="magnetic-wrapper-container">
        <div class="swiperloaders">
          <div class="greenloader">
            <div id="magnetic-content" class="magnetic-content-container">
              <div class="box-module" (click)="(item.customField30 == 0) ? imageNavigation(item.catId)
              : (item.customField30 == 1) ? dynamicFormPopup(item.value)
              : (item.customField30 == 2) ? link(item.navigatedUrl)
              : (item.customField30 == 3) ? scroll(item.customField35) : null">
                <div class="circle-module">
                  <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjE0MDggMS41MjczMUw0LjQ3MjY5IDIwLjE0MDgiIHN0cm9rZT0iIzBCMEMwRCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQuNjg5MzMgMy41ODc4OUwxOC4xNCAxLjUyNzMxTDIwLjIwMDYgMTQuOTc4IiBzdHJva2U9IiMwQjBDMEQiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=" alt="arrow" class="forward-icon" style="width: 22px;">
                  <p class="magnet-text">Let's Discuss <br>Your Idea</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
