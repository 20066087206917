import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-pyramidtext',
  templateUrl: './pyramidtext.component.html',
  styleUrls: ['./pyramidtext.component.scss']
})
export class PyramidtextComponent {
@Input() data : any;
  @Input() item : any;
  @Input() images : any;
    public bannerServerPath = environment.commonImageApi + '/commonSlider/';
  
    public serverPath1 = environment.commonImageApi + '/home/';
}
