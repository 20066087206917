import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { PurchaseService } from 'src/app/services/purchase.service';
import { Constant } from 'src/app/constants/constant';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { DynamicFormPopupComponent } from 'src/app/theme/components/dynamic-form-popup/dynamic-form-popup.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'calsoft-search-box1',
  templateUrl: './search-box1.component.html',
  styleUrls: ['./search-box1.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      state('open', style({
        opacity: 1,
        transform: 'scaleY(1)'
      })),
      state('closed', style({
        opacity: 0,
        transform: 'scaleY(0)'
      })),
      transition('open <=> closed', [
        animate('0.3s ease-in-out')
      ])
    ]),
  ]
})
export class SearchBox1Component {
  @Input() item: any;

  startDate: Date;
  endDate: Date;
  guestCount: string;
  isOpen1: boolean;
  value: string;
  specialcat: any;
  bookingInfo: any[] = [{ info: 'Room 1 - 1 Adult', adults: 1, children: 0 }];
  sortParam: string;
  productList: any;
  storePhoneNumber: any;
  productName: any;


  constructor(
    private purchaseService: PurchaseService,
    private configService: ConfigServiceService,
    private dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    this.updateBookingInfo();
    this.getProduct();
    this.productTypeYFilter();
    this.getConfigData()
  }

  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          this.storePhoneNumber = data['storePhoneNumber'];
        }, error => {

        }
      );
  }

  



  onProductChange(event, name) {
  if (event.isUserInput != false) {

        this.productName = name;
  }
}

  navigate() {
    let message = `Hi, I'm interested to Book the ${this.productName}.\n`;
    message += `Can you please provide more information about it, including the price?`;
    const url = `https://api.whatsapp.com/send?phone= ${encodeURIComponent(this.storePhoneNumber)}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  }

  incrementAdult(room: any) {
    room.adults++;
    this.updateBookingInfo();
  }

  decrementAdult(room: any) {
    if (room.adults > 1) {
      room.adults--;
    }
    this.updateBookingInfo();
  }

  updateBookingInfo() {

    let adult = 0;
    let room = 0;
    let children = 0
    this.bookingInfo.forEach((room, index) => {

      if (index == 0) {
        adult = room.adults;
        children = room.children;
        room = 1;
      } else {
        adult = adult + room.adults;
        children = children + room.children;
        room = 1 + 1;
      }

      room = index + 1;

    });

    room = this.bookingInfo.length;
    this.guestCount = adult + "Adult" + children + "Children" + room + "Room"
  }


  addRoom() {
    this.bookingInfo.push({ info: `Room ${this.bookingInfo.length + 1} - 1 Adult`, adults: 1, children: 0 });
    this.updateBookingInfo()

  }

  removeRoom(index: number) {
    this.bookingInfo.splice(index, 1);
    this.updateBookingInfo();
  }


  incrementChildren(room: any) {
    room.children++;
    this.updateBookingInfo()
  }

  decrementChildren(room: any) {
    if (room.children > 1) {
      room.children--;
    }
    this.updateBookingInfo()
  }

  showBottomDiv: boolean = false;

  toggleBottomDiv() {
    this.showBottomDiv = !this.showBottomDiv;
  }

  @Output() itemSelected = new EventEmitter<string>();
  isOpen = false;

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  toggleMenu1() {
    this.isOpen1 = !this.isOpen1;
  }

  onItemClick(item: string) {
    this.itemSelected.emit(item);
    this.isOpen = false; // Close the menu after selecting an item
  }
  public page: any = Constant.PAGE;
  public size: any = Constant.SIZE;

  //product

  filteredProductdropdownTypeList: any;

  dropdownProductTypeList: any;
  productdropdownList: any;
  public typeYFilterControl: UntypedFormControl = new UntypedFormControl();

  protected _onDestroy = new Subject<void>();

  getProduct() {
    this.sortParam = 'entityId' + "," + Constant.Dsc;
    this.purchaseService.getProductsNew(this.page, this.size, this.sortParam).subscribe(
      res => {
        this.productList = res['content'];

        this.dropdownProductTypeList = res['content'];
        this.filteredProductdropdownTypeList = this.dropdownProductTypeList;

      },
      error => {

      }
    )
  }


  productTypeYFilter() {
    this.typeYFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.typeProductidYFilter();
      });
  }


  protected typeProductidYFilter() {
    if (!this.dropdownProductTypeList) {
      return;
    }
    let search = this.typeYFilterControl.value;
    if (!search) {
      this.filteredProductdropdownTypeList = this.dropdownProductTypeList.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProductdropdownTypeList = this.dropdownProductTypeList.filter(status => status['name'].toLowerCase().indexOf(search) > -1)
  }

  getAllProductdowntype() {
    this.filteredProductdropdownTypeList = this.dropdownProductTypeList;
  }

  
    dynamicFormPopup() {
      if (window.innerWidth > 768) {
  
         let dialogRef = this.dialog.open(DynamicFormPopupComponent, {
            width: '790px',
         });
  
         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
  
         });
  
      }
      else {
         let dialogRef = this.dialog.open(DynamicFormPopupComponent, {
            minWidth: '100vw', height: '100vh',
         });
  
         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
  
         });
      }
  
   }
  

}
