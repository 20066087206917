import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartService } from 'src/app/services/cart.service';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { Constant } from 'src/app/constants/constant';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';

@Component({
  selector: 'calsoft-product-detail',
  templateUrl: './productdetailes.component.html',
  styleUrls: ['./productdetailes.component.scss']
})
export class ProductdetailesComponent implements OnInit {


  @Input() product: any;

  @Input() finalData: any;

  @Input() productList: any = [];

  @Input() type: any;
  @Input() showCartIcon: any;
  @Input() data: any;
  @Input() height: any;
  public bigImage = environment.ImageAPI1;
  @Input() name: any;

  @Input() userId: any;

  @Input() width: any;

  @Input() heightMobile: any;

  @Input() widthMobile: any;

  @Input() showCartIncremented: any;

  @Input() showConfigurableDropdown: any;


  search = new UntypedFormControl('');

  cartList: any[] = [];

  constructor(
    public cartServices: CartService,
    public snackBar: MatSnackBar,
    private subscriptionService : SubscriptionPriceService,
  ) {

  }
  qty = new FormControl(0);

  @Output() productAdded = new EventEmitter<any>();


  ngOnInit(): void {
    let index = this.finalData.findIndex(x => x.productId == this.product.entityId);
  
    let quantity = 0;
    if (index !== -1) {
      quantity = this.finalData[index].quantity;
    }
    this.qty.setValue(quantity);
  
    this.productAdded.emit(this.finalData);
  }
  
  getProducts() {
    this.addToCartProduct(this.product, 'cart', this.qty.value, 'update');
  }
  
  incrementQty() {
    const currentQty = this.qty.value;
    if (currentQty < this.product.qty) {
      this.qty.setValue(currentQty + 1);
      this.addToCartProduct(this.product, 'cart', this.qty.value, 'increment');
    }
  }
  
  decrementQty() {
    const currentQty = this.qty.value;
    if (currentQty > 0) {
      this.qty.setValue(currentQty - 1);
      this.addToCartProduct(this.product, 'cart', this.qty.value, 'decrement');
    }
  }
  
  addToCartProduct(product, type, value, operation) {
    let index = this.productList.findIndex(x => x.productId == product.entityId);
  
    let quantity = 1;
    if (index !== -1) {
      if (operation === 'increment') {
        quantity = this.productList[index].quantity + 1;
      } else if (operation === 'decrement') {
        quantity = this.productList[index].quantity - 1;
      }
    }

    let cgstTax=0.0;
    let sgstTax=0.0;
    let igstTax=0.0;
    let inclusive=this.product.calculateSubtotalOnly;
    
    if(this.product.isCustomizedValue!=null){

      if(this.product.isCustomizedValue.cgst!=null){

        cgstTax = this.product.isCustomizedValue.cgst

      }else{
        cgstTax =0.0;
      }
      

      if(this.product.isCustomizedValue.sgst!=null){

        sgstTax = this.product.isCustomizedValue.sgst

      }else{
        sgstTax =0.0;
      }

      if(this.product.isCustomizedValue.igst!=null){

        igstTax = this.product.isCustomizedValue.igst

      }else{
        igstTax =0.0;
      }


    }
  
   

    let rowTotalWithoutTax= this.product.isCustomizedValue.priceWithoutTaxamount;

   let totalTaxAmount = this.product.isCustomizedValue.taxAmount;
   
    let cartItem = {
      productId: product.entityId,
      sku: product.sku,
      name: product.name,
      quantity: quantity,
      tax: product.tax || 0,
      price: product.price || 0,
      specialPrice: product.specialPrice || 0,
      grandTotal: product.price * quantity,
      operationType: 'New',
      cgst:cgstTax,
      sgst:sgstTax,
      igst:igstTax,
      inclusive:inclusive,
      rowTotalWithoutTax: rowTotalWithoutTax,
      rowTotal:rowTotalWithoutTax+totalTaxAmount,
      totalTaxAmount:totalTaxAmount
    };
  
    if (index === -1) {
      this.productList.push(cartItem);
    } else {
      this.productList[index] = cartItem;
    }
  
    this.productAdded.emit(this.productList);
  }
  

  validateAndUpdateQty() {
    let enteredQty = this.qty.value;
    if (enteredQty > this.product.qty) {
      this.qty.setValue(this.product.qty);
      enteredQty = this.product.qty;
      this.addToCartProduct(this.product, 'cart', this.qty.value, 'update');
    } else if (enteredQty < 0) {
      this.qty.setValue(0);
      enteredQty = 0;
    }
    this.getProducts();
  }

}


