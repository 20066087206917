import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';


@Pipe({
  name: 'currencyConvert'
})
export class CurrencyConvertPipe implements PipeTransform {

  currencyRate: any;
  countryCode: string = 'INR';
  decimalDigit: number = 2;

  constructor(
    private currencyConversionService: CurrencyConversionService,
    private currencyPipe: CurrencyPipe,
    private commonService: CommonService,
    private configService: ConfigServiceService
  ) {
    this.getConfigData();
  }

  transform(value: any): string | null {
    if (value == null || isNaN(value)) return null;

    const digitsInfo = `1.${this.decimalDigit}-${this.decimalDigit}`;
    this.countryCode = this.currencyConversionService.getCountryCode() || this.commonService.getCurrencyValue();

    if (!this.countryCode || this.countryCode === 'INR') {
      const formattedValue = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: this.decimalDigit,
        maximumFractionDigits: this.decimalDigit
      }).format(value);
      return `₹ ${formattedValue}`;
    }

    this.currencyRate = this.currencyConversionService.getCurrencyValue() || 1;
    const convertedValue = value * this.currencyRate;

  //  return this.currencyPipe.transform(convertedValue, this.countryCode, 'symbol-narrow', digitsInfo);

    const formattedCurrency = this.currencyPipe.transform(convertedValue, this.countryCode, 'symbol-narrow', digitsInfo);
    return formattedCurrency ? formattedCurrency.replace(/(\D)(\d)/, '$1 $2') : null;
  }

  private getConfigData(): void {
    this.configService.getConfigData().subscribe(
      (data) => {
        if (data && data['decimalDigit'] != null) {
          this.decimalDigit = +data['decimalDigit'];
        }
      },
      (error) => {
        console.error('Error fetching config data:', error);
      }
    );
  }
}
