import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AboutService } from 'src/app/services/about.service';


@Component({
    selector: 'calsoft-layout-popup-type1',
    templateUrl: './layout-popup-type1.component.html',
    styleUrls: ['./layout-popup-type1.component.scss']
})
export class LayoutPopupType1Component implements OnInit {

  title: any;
  
  @Input() pagetitle: any;


  page: number;
  size: any;
  data: any;


  constructor(
    
    private aboutService: AboutService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
  ) {
    this.data = data

  }

  aboutData:any;
  

  ngOnInit() {

    console.log("page title" , this.data);

    this.title = this.data.pagetitle;
    
    
    if(this.data.pagetitle!=null){
      this.getAboutData(this.data.pagetitle);
    }




  }


  close(){
    this.dialog.closeAll();
  }


  getAboutData(pagetitle) {

    this.aboutService.getPageByIdNew(pagetitle,this.page,this.size).subscribe(
      (res) => {
        this.aboutData = res['content'];

      }
    )
  }

}

