<div class="coupon-container">
    <div class="header">
        <h2>{{title | titlecase }}</h2>
        <button (click)="close()" matDialogClose mat-icon-button class="close-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="coupon-container2">

        <div>

            <p [innerHTML]="aboutData"> </p>

        </div>

    </div>

</div>