<div   fxHide.lt-md > 
    <div fxFlex="40" fxLayoutAlign="center center">
        <mat-divider *ngIf="index >0"  horizontal="true" class="divider1" ></mat-divider>
    </div>
    <ul fxFlex fxLayoutGap="10px">
        <li  fxFlex="100">
            <div fxLayoutAlign="space-between center">
                
                <a class="cursor">
                    <div class="circle" fxLayoutAlign="center center">
                        <img class="icon" (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"  [src]="serverPath+img.img" [alt]="img.customField1" title="img.customField1" width="75%" height="75%"
                        class=" lzl-cached lzl-ed">
                    </div>
                    <div fxLayoutAlign="center center">
                        <h3 [innerHTML]="img.customField1"></h3>
                    </div>
                </a>
                <mat-divider *ngIf="index < totalItems - 1"  fxFlex="100" vertical="true" class="divider"></mat-divider>
            </div>
        </li>
    </ul>
</div>
<div  fxHide fxShow.lt-md >
    <ul  fxLayoutGap="10px">
        <li  fxFlex="100">
            <div  fxLayout="column" fxLayoutAlign="space-between center">
                <a class="cursor" fxLayout="column" fxLayoutAlign="center center" >
                    <div class="circle" fxLayoutAlign="center center">
                        <!-- <mat-icon class="icon">{{img.img}}</mat-icon> -->
                        <img (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"  [src]="serverPath+img.img" [alt]="img.customField1" title="img.customField1" width="50%" height="50%"
                        class=" lzl-cached lzl-ed">
                    </div>
                    <div fxLayoutAlign="center center">
                        <h3 [innerHTML]="img.customField1"></h3>
                    </div>
                </a>
                <mat-divider *ngIf="index < totalItems - 1"  horizontal="true" class="divider"></mat-divider>
            </div>
        </li>
    </ul>
    
</div>