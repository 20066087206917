import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-bennerwithlogin',
  templateUrl: './bennerwithlogin.component.html',
  styleUrls: ['./bennerwithlogin.component.scss']
})
export class BennerwithloginComponent implements OnInit {
  images = [
    'https://online.manappuram.com/images/sgl.jpg',
    'https://online.manappuram.com/images/slider1.jpg', // Add other image URLs
    'https://online.manappuram.com/images/lal.jpg',
    'https://online.manappuram.com/images/slider1.jpg'
  ];

  customOptions: any = {
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;

  @Input() data: any;




  constructor(
    private commonService: CommonService,
  ) {

  }
  userName = '';
  userEmail = '';
  ngOnInit(): void {
    this.checklogin();
    
  }
  loginned = false;
  checklogin() {
    const a = localStorage.getItem('authenticaterUser');
    this.userName = localStorage.getItem('authenticaterUser');
    this.userEmail = localStorage.getItem('authenticaterUserEmail');
    if(a){
      this.loginned = true;
    }
  }


  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }
  
  }

  link(url) {

    this.commonService.goToLink(url)
  }
  
  isClicked = false;

  triggerClickEffect() {
    this.isClicked = true;

    // Reset the effect after the animation completes
    setTimeout(() => {
      this.isClicked = false;
    }, 400);
  }
}
